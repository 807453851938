<template>
  <b-modal
    id="wechat-modal"
    ref="wechat-modal"
    size="xl"
    :scrollable="true"
    aria-label="Wechat modal content"
    modal-class="wechat-modal"
    :hide-header="true"
    :hide-footer="true"
    centered
  >
    <div class="modal-content wechat-modal-content">
      <div class="section-title-container">
        <h2 class="title lang" v-html="title" />
        <b-button
          role="button"
          class="modal-close"
          tabindex="0"
          @click="closeModal"
        >
          <inline-svg
            :src="require('@/assets/svg/icon-close.svg')"
            width="30"
            height="30"
            aria-label="Close icon"
            aria-hidden="true"
            class="svg-primary close"
          />
          <span class="sr-only">Close modal</span>
        </b-button>
      </div>
      <div class="content">
        <img
          v-if="image"
          :src="image"
          alt="Content image"
          aria-hidden="true"
          class="footerModal-image"
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'WeChatModal',
  components: {},
  props: ['track', 'title', 'image'],
  data: function () {
    return {
      isMounted: false
    }
  },
  computed: {},
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeModal () {
      this.$bvModal.hide('wechat-modal')
    }
  }
}
</script>

<style lang="scss">
.wechat-modal {
  .modal-body {
    margin: 5rem 1.2rem 0;
    padding: 0;
    background-color: getColor('dark-royal-blue');
    display: flex;
    .section-title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2rem 4rem 2rem 2rem;
      margin-top: -10px;

      .title {
        color: getColor('mango');
        font-size: 33px;
        line-height: 1.3em;
        margin-bottom: 0;
      }

      button.btn.modal-close{
        position: absolute;
        top: 14px;
        right: 14px;
        margin: 0;

        &:hover,
        &:focus {
          outline: none !important;
          border: none !important;
          background: none !important;
        }
        svg {
          position: relative;
          margin: 0;
        }
      }
    }
    .footerModal-image {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
    .content {
      color: white;
      padding: 0 2rem 2rem 2rem;
    }
  }
  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: initial;
    overflow: initial !important;
  }
  .wechat-modal-content {
    display: block;
  }
  + .modal-backdrop {
    background-color: $modal-background-color;
    opacity: $modal-background-opacity;
  }
}

@include media-breakpoint-up(md) {
  .wechat-modal {
    .modal-dialog {
      max-width: initial;
      min-height: initial;
      margin: 1.85rem 2.6rem 0;
    }
    .modal-body {
      margin: 0 24vw;
    }
  }
}

@include media-breakpoint-up(xl) {
  .wechat-modal {
    .modal-dialog {
      margin: 1.85rem 1.4rem 0;

      > .modal-content {
        width: 350px;
        margin: 0;
        position: absolute;
        right: -60px;
        bottom: 130px;
        margin-right: 15vw;
      }
    }

    .modal-body {
      margin: 0;
      .section-title-container {
        padding: 2rem 5rem 2rem 2rem;
        .title {
          font-size: 42px;
        }
      }
    }
  }
  body.modal-open {
    &.modal-id-wechat-modal {
      .wechat-link {
        pointer-events: none;

        .social-icon {
          z-index: 10000;
          position: relative;

          .stroke-to-change {
            stroke: getColor('mango')!important;
          }
          .fill-to-change {
            fill: getColor('mango') !important;
          }
        }
      }
    }
  }
}
</style>
