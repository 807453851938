import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const profiles = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_PROFILES
  }
}

/* Data Structure

{
  "achievements": {
    "race-operations": "silver",
    "hairpinHero": "bronze",
    "victoryLane": "silver",
    "builtForSpeed": "silver",
    "highOctane": "silver",
    "kingOfTheLean": "silver",
    "lightningSpeed": "silver",
    "swaggerSuit": "silver",
    "guardianOfGula": "gold",
    "redlineReady": "bronze",
    "gulaLegend": "gold",
    "raceReady": "gold",
    "pitMaster": "gold"
  },
  "activationsPlayed": {
    "motogp-simulators": 24,
    "registration-tablet": 777,
    "suit-up": 8,
    "batak": 97,
    "team-viewer": 35,
    "race-control": 222,
    "race-operations": 6,
    "virtual-wind-tunnel": 444,
    "race-face": 666,
    "unit_test_multiplayer": 999,
    "podium": 1,
    "driving-strength": 4,
    "pit-wall": 33,
    "dashboard": 111,
    "registration-kiosk": 888,
    "f3-simulator": 19,
    "pit-garage": 111
  },
  "colourScheme": "b",
  "driverNumber": "123456789",
  "first_name": "Kong",
  "last_name": "Yim",
  "gender": "male",
  "team": "rallyRed" // rallyRed, thunderPurple, etc
}

*/
