/* Plugin to allow scrolling to activation when loading website,
    sharing content or open modal of touchpoint.
*/

// import { resolve as env } from '@/common/env'
import { store } from '@/store'
import { find } from 'lodash'
import { saveAs } from 'file-saver'
import { isWeChat } from '@/utils/browser'
import { top } from '@/utils/position'

export default {
  install (Vue) {
    let activationComponentRef = null

    // Init reference to activation component
    Vue.initModal = function ($ref) {
      activationComponentRef = $ref
    }

    // load webpage and setup touchpoint
    Vue.loadActivation = function (touchpoint) {
      // scroll to the touchpoint on mobile and tablet
      // on desktop bring up the modal
      const currentActivation = touchpoint || store.state.touchpoint
      if (window.innerWidth < 1030) {
        // mobile and tablet
        const touchpointBounds = document
          .querySelector(`#touchpoint-${currentActivation}`)
          .getBoundingClientRect()

        let adjustForNavHeight = 0
        if (window.innerWidth < 768) {
          adjustForNavHeight = 70
        }

        const scrollToY =
          top() +
          touchpointBounds.y -
          adjustForNavHeight

        if (isWeChat) {
          window.scrollTo(0, scrollToY)
        } else {
          window.scrollTo({ top: scrollToY, behavior: 'smooth' })
        }
      } else {
        // desktop
        const activation = find(store.state.activations.content, {
          objectId: currentActivation
        })
        this.openModal(activation)
      }
    }

    Vue.shareSouvenir = function (activation) {
      // show share modal if navigator.share is not available
      const shareTitle = store.getters.getTranslationsKey('TL_TITLE')
      const shareText = store.getters.getTranslationsKey(`TL_${activation.objectId.toUpperCase()}`)
      // for vcc content we share that otherwise we share the image
      let shareUrl = Vue.shareUrl(activation)
      console.debug('Share:', shareTitle, shareText, shareUrl)
      try {
        window.navigator.share({ title: shareTitle, text: shareText, url: shareUrl }).catch((err) => {
          console.error('onShare', err.name)
        })
      } catch (err) {
        console.error('Share failed:', err.message)
      }
    }

    Vue.openModal = function (activation) {
      activationComponentRef.openModal(activation)
    }

    Vue.shareUrl = function (activation) {
      let shareUrl
      // for vcc content we share that otherwise we share the image
      const vccContent = store.state.vcc.content[activation?.objectId]

      if (vccContent) {
        shareUrl = vccContent.raw
      } else {
        shareUrl = `${window.location.origin}/profiles/${store.state.profileId}/activations/${activation?.objectId}.png`
      }
      return shareUrl
    }

    Vue.downloadFile = function (url, fileName) {
      saveAs(url, fileName) // { type: 'video/mp4' } {type: 'image/png'}
    }
  }
}
