var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"fade-in slow"},[_c('b-container',{staticClass:"container-footer",attrs:{"fluid":""}},[_c('b-row',{staticClass:"page-margin"},[_c('b-col',{staticClass:"links",attrs:{"sm":"12","md":"6"}},[_c('b-nav',{attrs:{"vertical":""}},[_c('b-nav-item',{attrs:{"link-classes":"primary"},on:{"click":function($event){return _vm.openModal(
                'About',
                'TL_ABOUT_MGPM',
                'TL_ABOUT_BODY',
                _vm.$store.state.global_content.content.about.image
              )}}},[_c('h4',{staticClass:"lang"},[_vm._v(" "+_vm._s(_vm.$store.getters['getTranslationsKey']('TL_ABOUT_MGPM'))+" ")])]),_c('b-nav-item',{attrs:{"link-classes":"primary"},on:{"click":function($event){return _vm.openModal('Terms', 'TL_TERMS_&_CONDITIONS', 'TL_T&C_BODY')}}},[_c('h4',{staticClass:"lang"},[_vm._v(" "+_vm._s(_vm.$store.getters['getTranslationsKey']('TL_TERMS_&_CONDITIONS'))+" ")])])],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"follow-us-title lang"},[_vm._v(" "+_vm._s(_vm.$store.getters['getTranslationsKey']('TL_FOLLOW_US'))+" ")])])],1),_c('b-row',[_c('b-col',[_c('b-nav',{staticClass:"col-social"},[_c('b-nav-item',{attrs:{"link-classes":"primary-svg","href":_vm.iconFacebook,"target":"_blank"},on:{"click":function($event){return _vm.socialClicked('facebook')}}},[_c('inline-svg',{staticClass:"svg-tertiary social-icon",attrs:{"src":require('@/assets/svg/icon-facebook.svg'),"width":"56","height":"56","aria-label":"Social icon","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow Facebook")])],1),_c('b-nav-item',{attrs:{"link-classes":"primary-svg","href":_vm.iconTwitter,"target":"_blank"},on:{"click":function($event){return _vm.socialClicked('twitter')}}},[_c('inline-svg',{staticClass:"svg-tertiary social-icon",attrs:{"src":require('@/assets/svg/icon-twitter.svg'),"width":"56","height":"56","aria-label":"Social icon","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow Twitter")])],1),_c('b-nav-item',{attrs:{"link-classes":"primary-svg wechat-link","href":_vm.iconWeChat,"target":"_blank"},on:{"click":function($event){return _vm.openWeChatModal($event,
                                    'WeChat',
                                    'TL_FOLLOW_US_ON_WECHAT',
                                    _vm.$store.state.global_content.content.wechat.image
                    )}}},[_c('inline-svg',{staticClass:"svg-tertiary social-icon",attrs:{"src":require('@/assets/svg/icon-wechat.svg'),"width":"56","height":"56","aria-label":"Social icon","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow Wechat")])],1),_c('b-nav-item',{attrs:{"link-classes":"primary-svg","href":_vm.iconInstagram,"target":"_blank"},on:{"click":function($event){return _vm.socialClicked('instagram')}}},[_c('inline-svg',{staticClass:"svg-tertiary social-icon",attrs:{"src":require('@/assets/svg/icon-instagram.svg'),"width":"56","height":"56","aria-label":"Social icon","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow Instagram")])],1),_c('b-nav-item',{attrs:{"link-classes":"primary-svg","href":_vm.iconDouyin,"target":"_blank"},on:{"click":function($event){return _vm.socialClicked('douyin')}}},[_c('inline-svg',{staticClass:"svg-tertiary social-icon",attrs:{"src":require('@/assets/svg/icon-douyin.svg'),"width":"56","height":"56","aria-label":"Social icon","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow Douyin")])],1)],1)],1)],1),_c('ScrollTop',{attrs:{"inline":true}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"copyright lang"},[_vm._v(" "+_vm._s(_vm.$store.getters['getTranslationsKey']('TL_COPYRIGHT'))+" ")])])],1)],1),_c('FooterModal',{attrs:{"track":_vm.modalTrack,"title":_vm.modalTitle,"content":_vm.modalContent,"image":_vm.modalImage}}),_c('WeChatModal',{attrs:{"track":_vm.modalTrack,"title":_vm.modalTitle,"content":_vm.modalContent,"image":_vm.modalImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }