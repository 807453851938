import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const translations = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_TRANSLATIONS
  }
}
