import Segment from 'analytics-node'
import { resolve as env } from '@/common/env'
import autoBind from 'auto-bind'

class Analytics {
  constructor () {
    autoBind(this)
    this.name = 'Analytics'
    this.anonymousId = this.uuidv4()
    this.profileId = false
    this.segment = false
  }

  init (profileId) {
    this.segmentId = env('VUE_APP_SEGMENT_WRITE_KEY')
    if (this.segmentId) {
      this.segment = new Segment(this.segmentId, { flushAt: 1 })
      this.profileId = profileId
      setTimeout(() => {
        const toSend = {
          anonymousId: this.anonymousId
        }
        if (this.profileId !== false) {
          toSend.userId = this.profileId
        }
        console.log(this.name, 'identify', toSend)
        this.segment.identify(toSend)
      }, 10)
    }
  }

  uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  page (name) {
    if (this.segment === false) {
      return
    }
    setTimeout(() => {
      const properties = {
        app_name: env('VUE_APP_APP_NAME'),
        brand_name: env('VUE_APP_BRAND_NAME'),
        show_name: env('VUE_APP_SHOW_NAME'),
        country: env('VUE_APP_COUNTRY'),
        city: env('VUE_APP_CITY')
      }
      const toSend = {
        name: name,
        anonymousId: this.anonymousId,
        properties
      }
      if (this.profileId !== false) {
        toSend.userId = this.profileId
      }
      console.log(this.name, 'page', toSend)
      this.segment.page(toSend)
    }, 10)
  }

  track (category, action, label = false, value = false, anonymous = false) {
    if (this.segment === false) {
      return
    }
    setTimeout(() => {
      const properties = {
        category,
        action,
        app_name: env('VUE_APP_APP_NAME'),
        brand_name: env('VUE_APP_BRAND_NAME'),
        show_name: env('VUE_APP_SHOW_NAME'),
        country: env('VUE_APP_COUNTRY'),
        city: env('VUE_APP_CITY')
      }
      if (label) {
        properties.label = label
      }
      if (value) {
        properties.value = value
      }
      const toSend = {
        event: 'event',
        anonymousId: this.anonymousId,
        properties
      }

      if (this.profileId !== false) {
        toSend.userId = this.profileId
      }
      console.log(this.name, 'track', toSend)
      this.segment.track(toSend)
    }, 10)
  }
}

export default new Analytics()
