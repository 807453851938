import { find, map, sortBy, values } from 'lodash'

const store = {
  appReady: false,
  appError: false,
  appErrorMessage: null,
  languages: [
    { name: 'Chinese', code: 'zhHT' },
    { name: 'Portugese', code: 'ptPT' },
    { name: 'English', code: 'enUS' }
  ],
  profileId: null,
  touchpoint: null,
  activation: null,
  achievement: null,
  team: null
}

const getters = {
  appContentLoaded: (state) => {
    return (
      state.activations?.loaded &&
       state.achievements?.loaded &&
      state.teams?.loaded &&
      state.global_content?.loaded &&
      state.translations?.loaded
    )
  },
  appReady: (state) => {
    return (
      state.activations?.loaded &&
      state.achievements?.loaded &&
      state.teams?.loaded &&
      state.global_content?.loaded &&
      state.translations?.loaded &&
      state.profiles?.loaded &&
      state.vcc?.loaded
    )
  },

  // Team colours
  teamColorBackground: (state) => {
    return state.team ? state.team?.background.colour : ''
  },
  teamColorText: (state) => {
    return state.team ? state.team?.text.colour : ''
  },
  // Activations sorted by order
  sortedActivations: (state) => {
    return sortBy(state.activations?.content, ['order'])
  },
  // get a list of any keys values (debugging purposes)
  getListOfKeyValues: (state) => (dataType, key) => {
    let values = map(state[dataType].content, key)
    return values
  },

  // generate all chinese content in system and place on home page so that webfont will be parsed correctly
  allChineseContent: (state) => {
    let translationsCopy = values(
      state.translations.content['zhHT']
    ).toString()
    let achievementsCopy = map(
      map(state.achievements.content, 'name'),
      'zhHT'
    ).toString()
    let teamsCopy = map(map(state.teams.content, 'name'), 'zhHT').toString()
    let profileCopy = state.profiles.content.first_name
    return translationsCopy + achievementsCopy + teamsCopy + profileCopy
  },

  /**
   * Translate content store to a language
   *
   * @param {String} cmsKey the store key name
   * @param {String} chosenLanguage the language to translate to (enUS, ptPT, zhHT)
   */
  translate: (state) => (cmsKey, chosenLanguage) => {
    let _cmsKey
    if (cmsKey === 'content') {
      _cmsKey = 'global_content'
    } else {
      _cmsKey = cmsKey
    }
    return state[_cmsKey].content
      ? state[_cmsKey].content[chosenLanguage || state.language.current]
      : undefined
  },
  /**
   * Translate specific data to a language
   *
   * @param {String} data data
   * @param {String} chosenLanguage the language to translate to (enUS, ptPT, zhHT)
   */
  translateData: () => (data, chosenLanguage) => {
    return data[chosenLanguage]
  },
  /**
   * Return specific key from the translations
   *
   * @param {String} key translation key (TL_WELCOME)
   * @param {String} chosenLanguage the language to translate to (enUS, ptPT, zhHT) default current
   */
  getTranslationsKey: (state) => (
    key,
    chosenLanguage = state.language.current
  ) => {
    return state.translations &&
      state.translations.loaded &&
      state.translations.content &&
      state.translations.content[chosenLanguage]
      ? state.translations.content[chosenLanguage][key]
      : ''
  }
}

const actions = {
  appError (context) {
    context.commit('appError')
  },
  appErrorMessage (context, value) {
    context.commit('appErrorMessage', value)
  },
  profileId (context, value) {
    context.commit('profileId', value)
  },
  touchpoint ({ commit, rootState }, activationId) {
    let activation = find(rootState.activations.content, {
      objectId: activationId
    })
    if (!activation) { console.error(`Activation data not found: ${activationId}`) }
    let achievement = find(rootState.achievements.content, {
      key: activation.achievement
    })
    if (!activation) { console.error(`Achievement data not found: ${activation.achievement}`) }
    let team = find(rootState.teams.content, {
      key: rootState.profiles.content.team
    })
    if (!team) console.error(`Team data not found: ${rootState.profiles.team}`)
    commit('touchpoint', activationId)
    commit('activation', activation)
    commit('achievement', achievement)
    commit('team', team)
  }
}

const mutations = {
  appError (state) {
    state.appError = true
  },
  appErrorMessage (state, value) {
    state.appErrorMessage = value
  },
  profileId (state, value) {
    state.profileId = value
  },
  touchpoint (state, value) {
    state.touchpoint = value
  },
  activation (state, value) {
    state.activation = value
  },
  achievement (state, value) {
    state.achievement = value
  },
  team (state, value) {
    state.team = value
  }
}

// setup a basic vuex interface to the pitwall storage
export const application = {
  state: store,
  getters,
  actions,
  mutations
}
