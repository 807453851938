import * as api from '@/api'

// A base store for the cms stores to extend from
// Just handles loading content & accessing current translation tree

export const mutators = {
  SET_CONTENT: 'SET_CONTENT',
  SET_LOADED: 'SET_LOADED'
}

export const store = {
  namespaced: true,
  state: {
    loaded: false,
    content: [],
    cmsKey: ''
  },
  actions: {
    async load ({ commit, state }, profileId) {
      const result = await api.getContent(state.cmsKey, profileId)
      console.debug(`load - ${state.cmsKey}`, result)
      commit(mutators.SET_CONTENT, result)
      commit(mutators.SET_LOADED, true)
    }
  },
  mutations: {
    [mutators.SET_CONTENT] (state, content) {
      state.content = content
    },
    [mutators.SET_LOADED] (state) {
      state.loaded = true
    }
  },
  getters: {
    // get translated content in current language
    translated (state, getters, rootState) {
      return state.content[state.cmsKey][rootState.language.current]
    }
  }
}
