// Very simple env wrapper to allow `VUE_APP_...` env vars
// to be overwritten at runtime by real env vars passed from docker
// this relies on :
// 1. the docker entrypoint files found at `@common/.docker`
// these must be set as your container entry point,
// they will parse the runtime env and write the vars to a JS file
// 2. your `index.html` must include the written file from the filesystem
// ```<script src="vue_app_env.js"></script>```
//
// Note - this file is only written/read when running stage/production
// this has no effect at dev time, where it will continue to use
// your local `.env` file to supply the `process.env` vars
// as part of the normal Vue-cli build process

import { get as loget, memoize } from 'lodash'

// load environment variables,
// override with window supplied vars at runtime
export const env = {
  ...process.env
}

export function get (prop, def) {
  return loget(env, prop, def)
}

const tokenex = /\$\{(.*?)\}/g

// Returns a property after resolving any deep env vars (using '${OTHER_VAR}' notation)
const resolve = memoize(function (prop, def) {
  var ret = get(prop, def)
  if (ret == null) return ret

  let match
  tokenex.lastIndex = -1
  while ((match = tokenex.exec(ret)) !== null) {
    var value = get(match[1])
    if (value == null) continue
    ret =
      ret.substr(0, match.index) +
      value +
      ret.substr(match.index + match[0].length)
  }
  return ret
})

export { resolve }
