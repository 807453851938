<template>
  <section class="section-activations fade-in slow delay9 page-margin">
    <b-container class="container-activations">
      <b-row>
        <b-col
          v-for="(currentActivation, index) in sortedActivations"
          :key="currentActivation.objectId"
          sm="12"
          xl="4"
          :index="index"
          :disabled="
            $store.state.profiles.content.activationsPlayed[currentActivation.objectId]
              ? false
              : true
          "
        >
          <div class="activation-item" :class="{disabled: $store.state.profiles.content.activationsPlayed[currentActivation.objectId]
            ? false
            : true}"
          >
            <div
              :id="`touchpoint-${currentActivation.objectId}`"
              :ref="`touchpoint-${currentActivation.objectId}`"
              class="activation-header"
              :style="{ 'background-color': currentActivation.colors.background }"
            >
              <inline-svg
                :src="currentActivation.icon"
                width="38"
                height="38"
                aria-label="activation icon"
                aria-hidden="true"
                class="activation-icon"
                :style="{ color: currentActivation.colors.icon }"
              />
              <h2 class="lang">
                {{
                  getTranslationsKey(`TL_${currentActivation.objectId.toUpperCase()}`)
                }}
              </h2>
            </div>
            <div class="activation-souvernir-container">
              <ActivationType :activation="currentActivation" @clicked="shareSouvenir" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <ActivationModal :activation="selectedActivation" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
// import { resolve as env } from "@/common/env";
import ActivationModal from '@/components/ActivationModal'
import ActivationType from '@/components/ActivationType'
// import { find } from "lodash";
import Vue from 'vue'
import analytics from '@/analytics'

export default {
  name: 'Activations',
  components: { ActivationType, ActivationModal },
  data: function () {
    return {
      activation: null
    }
  },
  computed: {
    ...mapGetters(['sortedActivations', 'getTranslationsKey']),
    selectedActivation () {
      return this.activation
    }
  },
  mounted () {
    Vue.initModal(this)
    if (this.$route.params.touchpoint) {
      analytics.track('souvenir', 'load-activation', this.$store.state.touchpoint)
      Vue.loadActivation()
    }
  },
  methods: {
    shareSouvenir (activation) {
      // show share modal if navigator.share is not available
      // console.log(this.$store.state, this.$store.state.vcc.content[activation.objectId], `${window.location.origin}/profiles/${this.$store.state.profileId}/activations/${activation.objectId}.png`)
      if (window.innerWidth <= 1024 && navigator.share != null) {
        Vue.shareSouvenir(activation)
        analytics.track('souvenir', 'share-navigator', activation.objectId)
      } else {
        // open modal for desktop
        analytics.track('modal', 'open', activation.objectId)
        this.openModal(activation)
      }
    },
    openModal (activation) {
      console.debug('openModal', activation)
      this.activation = activation
      this.$bvModal.show('activation-modal')
      // listen for window resize to hide modal when
      // window width < 1030
      window.addEventListener('resize', this.windowResize)
      this.$root.$once('bv::modal::hide', this.modalHidden)
    },
    modalHidden (bvEvent, modalId) {
      // console.log('Modal is about to be hidden', bvEvent, modalId)
      if (modalId === 'activation-modal') {
        window.removeEventListener('resize', this.windowResize)
      }
    },
    windowResize () {
      if (window.innerWidth < 1030) {
        this.$bvModal.hide('activation-modal')
      }
    }
  }
}
</script>

<style lang="scss">
.section-activations {
  margin: 0 calc(-15px - 0.5rem);
  background-color: getColor('dark-royal-blue');

  .activation-header {
    display: flex;
    align-items: center;
    padding: 0 30px;
    min-height: 100px;
    color: getColor('dark-royal-blue');

    h2 {
      font-size: 28px;
      margin: 4px 0 0;
    }

    .activation-icon {
      width: auto;
      height: 38px;
      margin-right: 16px;

      .fill-to-change {
        fill: currentColor !important;
      }
      .stroke-to-change {
        stroke: currentColor !important;
      }
    }
  }
  .activation-souvernir-container {
    position: relative;
    margin: 30px;
  }
}

@include media-breakpoint-up(md) {
  .section-activations {
    margin: 0 calc(-15px - 3.5rem) 20px;

    .activation-header {
      padding: 0 40px;
    }
    .activation-souvernir-container {
      margin: 30px 17%;
    }
  }
}
@include media-breakpoint-up(xl) {
  .section-activations {
    padding-top: 40px;
    padding-bottom: 100px;
    margin: 0;
    background-image: url('~@/assets/images/background-arrows.png');
    background-size: cover;
    background-position-x: -90%;

    .activation-item {
      &:not(.disabled) {
        &:hover {
          .activation-header {
            color: getColor('mango') !important;
          }
          .activation-souvernir-container{
            .btn-clean {
              .souvenir-image {
                border: 2px solid getColor('mango') !important;
              }
            }
          }
        }
      }
    }

    .activation-header {
      padding: 0;
      min-height: initial;
      background-color: transparent !important;
      color: white !important;
      transition: color 0.5s;

      h2 {
        font-size: 26px;
        min-height: 100px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;
      }

      .activation-icon {
        display: none;
      }
    }
    .activation-souvernir-container {
      margin: 0;

      .btn-clean {
        .souvenir-image {
          width: 100%;
          margin: 0;
          transition: border 0.5s;
        }
      }
      .corner {
        display: none;
      }
    }
  }
}
</style>
