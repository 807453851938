import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

// add current language ("lang-enUS", "lang-ptPT", "lang-zhHT") to body element
import addLanguageToBody from './utils/addLanguageToBody'

// util to handle sharing (mobile), opening (modal desktop) and scrolling to activations
import activationSharable from './utils/activationSharable'

import {
  LayoutPlugin,
  SpinnerPlugin,
  NavPlugin,
  NavbarPlugin,
  ModalPlugin,
  ButtonPlugin
} from 'bootstrap-vue'

// Plugins
// import { InlineSvgPlugin } from "vue-inline-svg";
// Vue.use(InlineSvgPlugin);

import InlineSvg from 'vue-inline-svg'
Vue.use(addLanguageToBody, { store })
Vue.use(activationSharable, { store })
Vue.component('InlineSvg', InlineSvg)

// Install BootstrapVue Plugins
Vue.use(LayoutPlugin)
Vue.use(SpinnerPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
