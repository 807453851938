<template>
  <b-button
    class="generic-button"
    :style="{ color: themeColor }"
    @click="$emit('clicked')"
  >
    <span class="label lang">{{ label }}</span><inline-svg
      :src="require('@/assets/svg/arrow-right.svg')"
      width="12"
      height="12"
      aria-label="Arrow right"
      aria-hidden="true"
      class="svg-primary arrow"
    />
  </b-button>
</template>

<script>
export default {
  name: 'GenericButton',
  components: {},
  props: ['label', 'themeColor'],
  computed: {}
}
</script>

<style lang="scss" scoped>

button.btn.generic-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  max-width: 300px;
  color: currentColor;

  &.mobile {
    max-width: 100%;
    color: white !important;
  }

  .label {
    padding: 0.7rem 0 0.7rem 1rem;
    line-height: 1em;
    transition: all 0.5s;
    position: relative;
  }
  .arrow {
    position: relative;
    left: -6px;
    transition: left 0.5s;

    .fill-to-change {
      fill: currentColor !important;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    top: 0;
  }
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    bottom: 0;
  }

  &:active,
  &:focus,
  &:hover,
  &.disabled,
  &:disabled {
    color: currentColor !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background: none !important;
  }

  &:hover:not(.disabled):not(:disabled) {
    .label {
      margin-left: 3px;
    }
    .arrow {
      left: -3px;
    }
  }
}

@include media-breakpoint-up(lg) {
  button.btn.generic-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    position: relative;

    .label {
      font-size: 26px;
      letter-spacing: -1.5px;
    }

    &.mobile {
      display: none
    }
  }
}
</style>
<style lang="scss">
button.btn.generic-button {
  .arrow {
    .fill-to-change {
      fill: currentColor !important;
    }
  }
}
</style>
