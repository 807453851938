<template>
  <div id="app" :class="rootClasses">
    <!-- <div id="nav" v-if="$store.state.config.debug">
      <router-link to="/enUS/id/touchpointId">Home</router-link> |
      <router-link to="/styleguide/colors">Styleguide - Colors</router-link>
      <router-link to="/styleguide/languages"
        >Styleguide - Languages</router-link
      >
    </div> -->
    <InitView v-if="!appReady && !$store.state.appError" />
    <router-view v-else />
    <div
      v-if="$store.state.language.current == 'zhHT'"
      class="chinese-content sr-only"
      aria-hidden="true"
    >
      {{ allChineseContent }}
    </div>
  </div>
</template>

<script>
import InitView from '@/views/Init.vue'
import { mapGetters } from 'vuex'
import { sample } from 'lodash'
import analytics from './analytics'

export default {
  name: 'App',
  components: {
    InitView
  },
  data: function () {
    return {
      lastLanguage: null
    }
  },
  computed: {
    ...mapGetters(['appReady', 'allChineseContent']),
    rootClasses () {
      return `theme-${this.$store.state.profiles?.content?.team} touchpoint-${this.$store.state.touchpoint}`
    },
    appError () {
      return this.$store.state.appError
    }
  },
  watch: {
    appReady () {
      console.debug('appReady!', this.$store)

      // set profile language
      this.$store.dispatch(
        'language/SET_LANGUAGE',
        this.$store.state.profiles.content.language
      )
      // this.$store.dispatch("language/SET_LANGUAGE", "ptPT");

      console.debug('profileId', this.$route.params.profile)
      console.debug('touchpoint', this.$route.params.touchpoint)

      // setup profileId
      const profileId = this.$route.params.profile
      if (profileId) {
        this.$store.dispatch('profileId', profileId)
        analytics.init(profileId)
      } else {
        analytics.init()
      }
      // setup touchpoint
      const touchpoint = this.$route.params.touchpoint
      if (touchpoint) {
        this.$store.dispatch('touchpoint', touchpoint)
      } else {
        // set default touchpoint to random
        let randomTouchpoint = sample(this.$store.state.activations.content)
        this.$store.dispatch('touchpoint', randomTouchpoint.objectId)
        console.debug('Random touchpoint', randomTouchpoint.objectId)
      }

      // set startup analytics
      if (profileId && touchpoint) {
        analytics.track('souvenir', 'scan:qrcode', touchpoint)
      } else if (profileId) {
        analytics.track('souvenir', 'open-from-email')
      }

      // console.debug(
      //   "allChineseContent",
      //   this.$store.getters["allChineseContent"]
      // );
      // console.debug(
      //   "team.keys",
      //   this.$store.getters["getListOfKeyValues"]("teams", "key")
      // );
      // console.debug(
      //   "activations.objectId",
      //   this.$store.getters["getListOfKeyValues"]("activations", "objectId")
      // );
      // console.debug(
      //   "activations.achievement",
      //   this.$store.getters["getListOfKeyValues"]("activations", "achievement")
      // );
      // console.debug(
      //   "achievements.keys",
      //   this.$store.getters["getListOfKeyValues"]("achievements", "key")
      // );
    },
    appError () {
      if (this.$store.state.appError) {
        this.$router.push({ name: 'Error' })
      }
    }
  },
  mounted () {
    // load all content on app start
    this.$store.dispatch('activations/load')
    this.$store.dispatch('achievements/load')
    this.$store.dispatch('teams/load')
    this.$store.dispatch('global_content/load')
    this.$store.dispatch('translations/load')

    if (window.location.pathname.includes('/profiles/')) {
      const profileId = window.location.pathname
        .replace('/profiles/', '')
        .split('/')[0]
      this.$store.dispatch('profiles/load', `profiles/${profileId}`)
      this.$store.dispatch('vcc/load', `profiles/${profileId}/vcc`)
    } else {
      // error
      console.error('No profile id!')
      this.$store.dispatch('appError', true)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  overflow-x: hidden;
}

#nav {
  position: absolute;
  right: 50px;
  top: 10px;
  @include zindex('nav-debug');

  a {
    font-weight: bold;
    color: #fff;
    padding: 10px;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
