<template>
  <button
    class="mobile-download-button"
    :style="{ color: themeColor }"
    @click="$emit('clicked')"
  >
    <span class="label lang">{{ label }}</span><inline-svg
      :src="require('@/assets/svg/arrow-right.svg')"
      width="12"
      height="12"
      aria-label="Arrow right"
      aria-hidden="true"
      class="svg-primary arrow"
    />
  </button>
</template>

<script>
export default {
  name: 'MobileDownloadButton',
  components: {},
  props: ['label', 'themeColor'],
  computed: {}
}
</script>

<style lang="scss" scoped>

button.mobile-download-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  max-width: 300px;
  color: currentColor;
  background: transparent;
  border-width: 0px;
  cursor: pointer;
  margin-top: 26px;

  &.mobile {
    max-width: 100%;
    color: white !important;
  }

  .label {
    padding: 0.7rem 0 0.7rem 1rem;
    line-height: 1em;
    transition: all 0.5s;
    position: relative;
  }
  .arrow {
    position: relative;
    left: -6px;
    transition: left 0.5s;

    .fill-to-change {
      fill: currentColor !important;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    top: 0;
  }
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    bottom: 0;
  }

  &:hover {
    .label {
      margin-left: 3px;
    }
    .arrow {
      left: -3px;
    }
  }
}

@include media-breakpoint-up(lg) {
  button.mobile-download-button.mobile {
    display: none
  }
}

button.mobile-download-button {
  .arrow {
    .fill-to-change {
      fill: currentColor !important;
    }
  }
}
</style>
