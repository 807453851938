<template>
  <div class="user-details">
    <div>
      <div
        class="number-team"
        :class="{
          hasWhiteBorder: teamHasWhiteBorder,
        }"
      >
        <span
          class="driver-number"
          :style="{ 'background-color': teamNumberBox, color: teamNumberText }"
        >{{ $store.state.profiles.content.driverNumber }}</span>
        <span class="team-icon">
          <inline-svg
            :src="teamIcon"
            height="30"
            aria-label="Team icon"
            :style="{
              color: teamIconColor,
            }"
          />
        </span>
      </div>
    </div>
    <div
      class="name lang"
      :style="{ 'background-color': teamNameBox, color: teamNameText }"
    >
      <template v-if="$store.state.profiles.content.language === 'zhHT'">
        {{ $store.state.profiles.content.last_name }}{{ $store.state.profiles.content.first_name }}
      </template>
      <template v-if="$store.state.profiles.content.language !== 'zhHT'">
        {{ $store.state.profiles.content.first_name }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetails',
  components: {},
  computed: {
    teamHasWhiteBorder () {
      return this.$store.state.team?.numberWhiteBorder
    },
    teamNumberText () {
      return this.$store.state.team?.numberText.colour
    },
    teamNumberBox () {
      return this.$store.state.team?.numberBox.colour
    },
    teamIcon () {
      return this.$store.state.team ? this.$store.state.team.icon : ''
    },
    teamIconColor () {
      return this.$store.state.activation ? this.$store.state.team?.colour : ''
    },
    teamNameBox () {
      return this.$store.state.team?.nameBox.colour
    },
    teamNameText () {
      return this.$store.state.team?.nameText.colour
    }
  }
}
</script>

<style lang="scss" scoped>

.user-details {
  padding-top: 48px;
  // display: inline-flex;
  // flex-direction: column;

  .number-team {
    display: inline-flex;
    align-items: center;
    position: relative;
    background-color: white;

    &.hasWhiteBorder {
      border: 2px solid white;
    }

    .driver-number {
      font-family: GTAmerica-ExpandedMedium;
      padding: 21px 15px;
      font-size: 25px;
      min-width: 42px;
      min-height: 42px;
      line-height: 0;
    }

    .team-icon {
      height: 100%;
      min-width: 42px;
      min-height: 42px;
      display: flex;
      align-items: center;
    }
  }

  .name {
    padding: 8px 48px 8px 12px;
    font-size: 20px;
    display: inline-block;
    line-height: 1.2em;
    min-height: 42px;
    max-width: 280px;
    word-break: break-all;
    min-width: 140px;
  }
}
@include media-breakpoint-up(md) {
  .user-details {
    padding-top: 0;
  }
}
@include media-breakpoint-up(lg) {
  .user-details {
    transform: scale(1.2);
  }
}
</style>

<style lang="scss">
.user-details {
  .team-icon {
    .fill-to-change {
      fill: currentColor !important;
    }
  }
}
</style>
