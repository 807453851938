export const mutators = {
  SET_LANGUAGE: 'SET_LANGUAGE'
}

export const language = {
  namespaced: true,
  state: {
    current: '',
    options: ['enUS', 'ptPT', 'zhHT']
  },
  actions: {
    [mutators.SET_LANGUAGE] (context, value) {
      context.commit(mutators.SET_LANGUAGE, value)
    }
  },
  mutations: {
    [mutators.SET_LANGUAGE] (state, language) {
      if (state.options.includes(language)) state.current = language
    }
  }
}
