import axios from 'axios'
import { config } from '@/store/config'
import { get } from 'lodash'
import { store } from '@/store'

export async function getContent (resource, apiRequest) {
  let resourceUrl
  if (apiRequest) {
    resourceUrl = `${get(config.state, 'serverApi', false)}/${apiRequest}`
  } else {
    resourceUrl = `${get(config.state, 'serverApi', false)}/${resource}`
  }
  console.debug('resourceUrl', resourceUrl)
  const { data } = await axios.get(resourceUrl).catch(() => {
    store.dispatch('appErrorMessage', 'Trouble loading resource!')
    store.dispatch('appError', true)
    throw new Error(`Failed to get data remotely ${resourceUrl}`)
  })

  return data
}
