<template>
  <transition duration="800">
    <div v-if="isMounted" class="home">
      <div v-if="$store.state.config.debug" class="placeholder">
        <!-- Home Navigation -->
        <!-- <img
          src="@/assets/placeholders/00-01_MyGarage(Desktop)_Homepage_RedRally.png"
          alt="Placeholder"
          class="show-desktop"
        />
        <img
          src="@/assets/placeholders/00-01_MyGarage(Tablet)_Homepage_RedRally.png"
          alt="Placeholder"
          class="show-tablet"
        />
        <img
          src="@/assets/placeholders/00-01_MyGarage(Mobile)_Homepage_RedRally.png"
          alt="Placeholder"
          class="show-mobile"
        /> -->

        <!-- Home Footer -->
        <!-- <img
          src="@/assets/placeholders/00-01_MyGarage(Desktop)_Homepage_RedRally.png"
          alt="Placeholder"
          class="show-desktop"
          style="margin-top: -3170px"
        />
        <img
          src="@/assets/placeholders/00-03_MyGarage(Tablet)_Homepage_Footer.png"
          alt="Placeholder"
          class="show-tablet"
          style="margin-top: -510px"
        />
        <img
          src="@/assets/placeholders/00-01_MyGarage(Mobile)_Footer.png"
          alt="Placeholder"
          class="show-mobile"
          style="margin-top: -150px"
        /> -->

        <!-- Home T&C -->
        <!-- <img
          src="@/assets/placeholders/99-01_MyGarage(Desktop)_T_C.png"
          alt="Placeholder"
          class="show-desktop"
        />
        <img
          src="@/assets/placeholders/99-01_MyGarage(Tablet)_T_C.png"
          alt="Placeholder"
          class="show-tablet"
        />
        <img
          src="@/assets/placeholders/99-01_MyGarage(Mobile)_T_C.png"
          alt="Placeholder"
          class="show-mobile"
        /> -->
      </div>

      <Navigation />
      <ScrollTop :inline="false" />

      <main>
        <Welcome />
        <Activations />
      </main>
      <Footer />
    </div>
  </transition>
</template>

<script>
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'
import ScrollTop from '@/components/ScrollTop'
import Welcome from '@/components/Welcome'
import Activations from '@/components/Activations'

export default {
  name: 'Home',
  components: { Navigation, Footer, ScrollTop, Welcome, Activations },
  data: function () {
    return {
      isMounted: false
    }
  },
  computed: {},
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped></style>
