<template>
  <div class="user-badges">
    <div class="user-badges-container">
      <inline-svg
        :src="require('@/assets/svg/corner.svg')"
        width="20"
        height="20"
        aria-label="corner"
        aria-hidden="true"
        class="corner corner-top"
      />
      <div class="badges-icons">
        <div
          v-for="(activation, index) in sortedActivations"
          :key="activation.objectId"
          :index="index"
        >
          <img
            :src="achievementResultIcon(activation).url"
            aria-label="Achievment"
            aria-hidden="true"
            class="achievement-icon"
            :class="{
              'no-achievement': achievementResultIcon(activation)
                .achievementIncomplete,
            }"
          >
        </div>
      </div>
      <inline-svg
        :src="require('@/assets/svg/corner.svg')"
        width="20"
        height="20"
        aria-label="corner"
        aria-hidden="true"
        class="corner corner-bottom"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
export default {
  name: 'UserDetails',
  computed: {
    ...mapGetters(['sortedActivations'])
  },
  methods: {
    matchActivation (activation) {
      const matchedActivation = find(this.$store.state.activations.content, {
        achievement: activation.achievement
      })
      return matchedActivation
    },
    achievementResultIcon (activation) {
      let achievementIncomplete = false
      const matchedAchievement = find(this.$store.state.achievements.content, {
        key: activation.achievement
      })
      const userAchievementScore = this.$store.state.profiles.content
        .achievements[matchedAchievement.key] // bronze, silver, gold
      // console.debug(
      //   "userAchievementScore",
      //   activation.achievement,
      //   userAchievementScore,
      //   matchedAchievement[userAchievementScore]
      // );
      let icon
      if (userAchievementScore) {
        icon = matchedAchievement[userAchievementScore]
      } else {
        icon = matchedAchievement.icon
        achievementIncomplete = true
      }
      return {
        url:
          this.$store.state.achievements?.content &&
          this.$store.state.profiles?.content
            ? icon
            : '',
        achievementIncomplete: achievementIncomplete
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-badges {
  // background-color: rgba(getColor("dark-royal-blue"), 0.5);
  width: 92%;
  padding: 70px 18% 70px 26px;
  margin: 106px 0 86px;
  min-height: 436px;

  &::before {
    content: "";
    position: absolute;
    left: -1000px;
    right: 10%;
    top: 106px;
    bottom: 86px;
    background-color: getColor("dark-royal-blue");
  }

  .user-badges-container {
    position: relative;
    @media only screen and (max-width: 767px) and (min-width: 600px)  {
      max-width: 360px;
    }
  }

  .badges-icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr 1fr;
    // justify-items: center;
    grid-gap: 28px 30px;

    > div {
      text-align: center;
    }
  }

  .achievement-icon {
    width: 100%;
    max-width: 80px;

    &.no-achievement {
      filter: invert(100%);
      opacity: 0.3;
    }
  }

  .corner {
    position: absolute;

    &.corner-top {
      left: -16px;
      top: -16px;
    }

    &.corner-bottom {
      right: -14px;
      bottom: -12px;
      transform: rotate(180deg);
    }
  }
}
@include media-breakpoint-up(md) {
  .user-badges {
    width: 48%;
    padding: 60px 9% 60px 26px;
    margin: 36px 0 156px;
    min-height: 294px;

    &::before {
      top: 36px;
      right: 52%;
      bottom: 156px;
    }

    .badges-icons {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 24px 20px;
    }

    .corner {
      &.corner-top {
        left: -20px;
        top: -16px;
        transform: scale(0.7);
      }
      &.corner-bottom {
        right: -20px;
        bottom: -16px;
        transform: rotate(180deg) scale(0.7);
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .user-badges {
    width: 89%;
    padding: 76px 18% 80px 16px;
    margin: 76px 0 200px;
    min-height: 394px;

    &::before {
      right: 14%;
      bottom: 200px;
      top: 76px;
    }

    .badges-icons {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 24px 20px;
    }

    .corner {
      &.corner-top {
        left: -20px;
        top: -16px;
        transform: scale(0.7);
      }
      &.corner-bottom {
        right: -20px;
        bottom: -16px;
        transform: rotate(180deg) scale(0.7);
      }
    }
  }
}
</style>

<style lang="scss">
.user-badges {
  .corner {
    .fill-to-change {
      fill: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>
