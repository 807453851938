import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const achievements = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_ACHIEVEMENTS
  }
}

/* Data Structure

{
  "key": "raceReady",
  "_id": "khoaoeg6kho9huckycp79kqm",
  "bronze": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaonwykho9huckygjwn1dv",
  "bronzePng": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaonxskho9huckysexi2ef",
  "gold": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaonygkho9huckylcoznj7",
  "goldPng": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaonzkkho9huckysoasgj4",
  "icon": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo0skho9huckydw1ps6w",
  "iconPng": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo1lkho9huckyw00b6jx",
  "iconWithBackground": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo2akho9huckydmzuofz",
  "iconWithBackgroundPng": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo32kho9huckym0tfpiy",
  "silver": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo3okho9huckycdtfzr6",
  "silverPng": "/cms/api/achievements/khoaoeg6kho9huckycp79kqm/attachments/khoaoo4nkho9hucky53pqbee",
  "name": {
    "enUS": "Race Ready",
    "ptPT": "Pronto para a Corrida",
    "zhHT": "整裝待發"
  }
}

*/
