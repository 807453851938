<template>
  <b-modal
    id="footer-modal"
    ref="footer-modal"
    size="xl"
    :scrollable="true"
    aria-label="Footer modal content"
    modal-class="footer-modal"
    :hide-header="true"
    :hide-footer="true"
    centered
  >
    <!-- <div class="placeholder" v-if="$store.state.config.debug">
      <img
        src="@/assets/placeholders/99-01_MyGarage(Desktop)_T_C.png"
        alt="Placeholder"
        class="show-desktop"
      />
      <img
        src="@/assets/placeholders/99-01_MyGarage(Tablet)_T_C.png"
        alt="Placeholder"
        class="show-tablet"
      />
      <img
        src="@/assets/placeholders/99-01_MyGarage(Mobile)_T_C.png"
        alt="Placeholder"
        class="show-mobile"
      />
    </div> -->

    <div class="modal-content footer-modal-content">
      <div class="section-title-container">
        <h2 class="title lang" v-html="title" />
        <b-button
          role="button"
          class="modal-close"
          tabindex="0"
          @click="closeModal"
        >
          <inline-svg
            :src="require('@/assets/svg/icon-close.svg')"
            width="30"
            height="30"
            aria-label="Close icon"
            aria-hidden="true"
            class="svg-primary close"
          />
          <span class="sr-only">Close modal</span>
        </b-button>
      </div>
      <div class="content">
        <img
          v-if="image"
          :src="image"
          alt="Content image"
          aria-hidden="true"
          class="footerModal-image"
        >
        <p class="lang" v-html="content" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import analytics from '@/analytics'
export default {
  name: 'FooterModal',
  components: {},
  props: ['track', 'title', 'content', 'image'],
  data: function () {
    return {
      isMounted: false
    }
  },
  computed: {},
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeModal () {
      analytics.track('footer', 'close', this.track)
      this.$bvModal.hide('footer-modal')
    }
  }
}
</script>

<style lang="scss">
.footer-modal {
  .modal-body {
    margin: 5rem 1.2rem 0;
    padding: 0;
    background-color: getColor('dark-royal-blue');
    display: flex;
    .section-title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      // background: grey;
      padding: 2rem 2.8rem 2rem 2rem;
      margin-top: -10px;

      .title {
        color: getColor('mango');
        font-size: 33px;
        line-height: 1.4em;
        margin-bottom: 0;
      }
    }
    .footerModal-image {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
    .content {
      color: white;
      padding: 0 2.8rem 2rem 2rem;
    }
  }
  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: initial;
    min-height: 100vh;
    overflow: initial !important;
  }
  .footer-modal-content {
    display: block;

    @include media-breakpoint-up(md) {
      button.btn.modal-close {
          &:hover {
          color: getColor('dark-royal-blue') !important;
          background-color: transparent !important;

          .stroke-to-change {
            stroke: currentColor !important;
          }
        }
      }
    }
  }
  + .modal-backdrop {
    background-color: $modal-background-color;
    opacity: $modal-background-opacity;
  }
}

@include media-breakpoint-up(md) {
  .footer-modal {
    // padding-top: 3.2vh;

    .modal-dialog {
      max-width: initial;
      min-height: initial;
      margin: 1.85rem 2.6rem 0;

      > .modal-content {
        min-height: 100vh;
      }
    }
    .modal-body {
      // max-height: 80vh;
      .section-title-container {
        position: fixed;
        width: calc(100vw - 7.6rem);
        background-color: getColor('mango');

        .title {
          color: getColor('dark-royal-blue');
        }
      }
      .content {
        margin-top: 110px;
        padding: 3rem 3.4rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer-modal {
    // padding-top: 20vh;
    .modal-dialog {
      margin: 1.85rem 12.4rem 0;
    }
    .modal-body {
      // margin: 0 12rem 0; //8%

      .section-title-container {
        // position: fixed;
        width: calc(100vw - 27.2rem);
        // width: calc(100vw - 29.2rem); // 24
        // padding: 3.4rem 3.4rem 3rem 3.2rem;

        .title {
          font-size: 42px;
        }
      }
      .content {
        padding: 5.4rem 5rem;
      }
    }
  }
}
</style>
