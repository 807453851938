<template>
  <section
    class="section-main page-margin"
    :style="{ 'background-color': teamColorBackground, color: teamColorText }"
  >
    <div class="lines">
      <inline-svg
        :src="require('@/assets/svg/lines.svg')"
        width="20"
        height="20"
        aria-label="lines"
        aria-hidden="true"
        class="lines-svg"
      />
    </div>
    <div
      class="welcomeBackgroundImage"
      :style="{ 'background-image': backgroundImage }"
    />
    <b-container class="container-welcome slide-right slow">
      <b-row>
        <b-col>
          <h1 class="welcome-title lang">
            {{ getTranslationsKey('TL_WELCOME') }}
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="check-out lang">
            {{ getTranslationsKey('TL_CHECK_OUT') }}
            {{ activationName }}
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="container-view fade-in slow delay3">
      <b-row>
        <b-col sm="12" md="5" class="view-now">
          <GenericButton
            :label="$store.getters['getTranslationsKey']('TL_VIEW_NOW')"
            :theme-color="teamColorText"
            @clicked="viewClicked"
          />
        </b-col>
        <b-col sm="12" md="7"><UserDetails /></b-col>
      </b-row>
    </b-container>

    <b-container class="container-badges fade-in slow delay6">
      <b-row>
        <b-col lg="6"><UserBadges /></b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import GenericButton from '@/components/GenericButton'
import UserDetails from '@/components/UserDetails'
import UserBadges from '@/components/UserBadges'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import analytics from '@/analytics'

export default {
  name: 'Footer',
  components: { GenericButton, UserDetails, UserBadges },
  computed: {
    ...mapGetters([
      'teamColorBackground',
      'teamColorText',
      'getTranslationsKey'
    ]),
    backgroundImage () {
      const backgroundImage = this.$store.state.team?.colourSchemes[this.$store.state.profiles.content.colourScheme].fullBody
      return `url('${backgroundImage}')`
    },
    activationName () {
      return this.$store.getters.getTranslationsKey(
        `TL_${this.$store.state.activation?.objectId.toUpperCase()}`
      )
    }
  },
  mounted () {},
  methods: {
    viewClicked () {
      analytics.track('scroll-to', this.$store.state.touchpoint)
      Vue.loadActivation()
    }
  }
}
</script>

<style lang="scss" scoped>
.section-main {
  // background-color: transparent !important;
  position: relative;

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .lines-svg {
      position: absolute;
      width: 1600px;
      height: 1600px;
      top: 60px;
      left: 42%;
    }
  }

  .container-welcome {
    padding-top: 2.6rem;

    .check-out {
      margin: 0.5rem 0 1.1rem;
      max-width: 50%;
      line-height: 1.2em;
    }

    .user-details {
      width: 52%;
    }

    @include media-breakpoint-up(md) {
      padding-top: 6rem;

      .check-out {
        margin: 0.4rem 0 1rem;
        max-width: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: 8rem;

      .check-out {
        margin: 1rem 0 1.4rem;
      }
    }
  }

  .container-view {
    .view-now {
      width: 50%;
    }
  }

  .welcomeBackgroundImage {
    // background-image: url("/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khxewgbpkho9hucky1hbmnrx");
    background-size: 104% auto;
    background-position: -660% 60px;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 72px;
    left: 0;
  }
}
@include media-breakpoint-up(md) {
  .section-main {
    .lines {
      .lines-svg {
        width: 1600px;
        height: 1600px;
        top: 180px;
        left: 56%;
      }
    }

    .view-now {
      padding-right: 40px;

      + div {
        margin-left: -8px;
      }
    }

    .welcomeBackgroundImage {
      background-size: 55% auto;
      background-position: 120% 20px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .section-main {
    .lines {
      .lines-svg {
        width: 2600px;
        height: 2600px;
        top: 150px;
        left: 50%;
      }
    }

    .view-now {
      padding-right: 100px;

      + div {
        margin-left: 0px;
      }
    }

    .welcomeBackgroundImage {
      background-size: 40% auto;
      background-position: 94% 60px;
      right: 10%;
    }
  }
}
</style>
<style lang="scss">
.section-main {
  .lines {
    .lines-svg {
      .fill-to-change {
        fill: white;
      }
    }
  }
}
</style>
