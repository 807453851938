import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const activations = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_ACTIVATIONS
  }
}

/* Data Structure

{
    "_id": "khoaodorkho9hucky20ejuxz",
    "objectId": "batak",
    "unitId": "1",
    "maxNumberGames": -1,
    "mode": "singleplayer",
    "colors": {
      "icon": "#ffffff",
      "lines": "#001969",
      "text": "#001969",
      "background": "#ff503c",
      "lighting": "#ff0000"
    },
    "screens": {
      "nowTakeYourPlace": false,
      "wellDoneWithQRCode": false
    },
    "scoreType": "points",
    "minSilver": 90,
    "maxSilver": 120,
    "achievement": "lightningSpeed",
    "headerIcon": "/cms/api/settings_activations/khoaodorkho9hucky20ejuxz/attachments/khoaopw6kho9hucky9bkobzg",
    "icon": "/cms/api/settings_activations/khoaodorkho9hucky20ejuxz/attachments/khoaopx3kho9huckyo1jpvz8",
    "tutorialVideo": "/cms/api/settings_activations/khoaodorkho9hucky20ejuxz/attachments/khob13q8kho9huckypvt8ip2"
  }

*/
