<template>
  <footer class="fade-in slow">
    <b-container class="container-footer" fluid>
      <b-row class="page-margin">
        <b-col sm="12" md="6" class="links">
          <b-nav vertical>
            <b-nav-item
              link-classes="primary"
              @click="
                openModal(
                  'About',
                  'TL_ABOUT_MGPM',
                  'TL_ABOUT_BODY',
                  $store.state.global_content.content.about.image
                )
              "
            >
              <h4 class="lang">
                {{ $store.getters['getTranslationsKey']('TL_ABOUT_MGPM') }}
              </h4>
            </b-nav-item>
            <b-nav-item
              link-classes="primary"
              @click="
                openModal('Terms', 'TL_TERMS_&_CONDITIONS', 'TL_T&C_BODY')
              "
            >
              <h4 class="lang">
                {{
                  $store.getters['getTranslationsKey']('TL_TERMS_&_CONDITIONS')
                }}
              </h4>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col sm="12" md="6">
          <b-container fluid>
            <b-row>
              <b-col>
                <h4 class="follow-us-title lang">
                  {{ $store.getters['getTranslationsKey']('TL_FOLLOW_US') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-nav class="col-social">
                  <b-nav-item
                    link-classes="primary-svg"
                    :href="iconFacebook"
                    target="_blank"
                    @click="socialClicked('facebook')"
                  >
                    <inline-svg
                      :src="require('@/assets/svg/icon-facebook.svg')"
                      width="56"
                      height="56"
                      aria-label="Social icon"
                      aria-hidden="true"
                      class="svg-tertiary social-icon"
                    />
                    <span class="sr-only">Follow Facebook</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="primary-svg"
                    :href="iconTwitter"
                    target="_blank"
                    @click="socialClicked('twitter')"
                  >
                    <inline-svg
                      :src="require('@/assets/svg/icon-twitter.svg')"
                      width="56"
                      height="56"
                      aria-label="Social icon"
                      aria-hidden="true"
                      class="svg-tertiary social-icon"
                    />
                    <span class="sr-only">Follow Twitter</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="primary-svg wechat-link"
                    :href="iconWeChat"
                    target="_blank"
                    @click="
                      openWeChatModal($event,
                                      'WeChat',
                                      'TL_FOLLOW_US_ON_WECHAT',
                                      $store.state.global_content.content.wechat.image
                      )
                    "
                  >
                    <inline-svg
                      :src="require('@/assets/svg/icon-wechat.svg')"
                      width="56"
                      height="56"
                      aria-label="Social icon"
                      aria-hidden="true"
                      class="svg-tertiary social-icon"
                    />
                    <span class="sr-only">Follow Wechat</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="primary-svg"
                    :href="iconInstagram"
                    target="_blank"
                    @click="socialClicked('instagram')"
                  >
                    <inline-svg
                      :src="require('@/assets/svg/icon-instagram.svg')"
                      width="56"
                      height="56"
                      aria-label="Social icon"
                      aria-hidden="true"
                      class="svg-tertiary social-icon"
                    />
                    <span class="sr-only">Follow Instagram</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="primary-svg"
                    :href="iconDouyin"
                    target="_blank"
                    @click="socialClicked('douyin')"
                  >
                    <inline-svg
                      :src="require('@/assets/svg/icon-douyin.svg')"
                      width="56"
                      height="56"
                      aria-label="Social icon"
                      aria-hidden="true"
                      class="svg-tertiary social-icon"
                    />
                    <span class="sr-only">Follow Douyin</span>
                  </b-nav-item>
                </b-nav>
              </b-col>
            </b-row>

            <ScrollTop :inline="true" />
          </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="copyright lang">
            {{ $store.getters['getTranslationsKey']('TL_COPYRIGHT') }}
          </p>
        </b-col>
      </b-row>
    </b-container>

    <FooterModal
      :track="modalTrack"
      :title="modalTitle"
      :content="modalContent"
      :image="modalImage"
    />

    <WeChatModal
      :track="modalTrack"
      :title="modalTitle"
      :content="modalContent"
      :image="modalImage"
    />
  </footer>
</template>

<script>
import FooterModal from './FooterModal'
import WeChatModal from './WeChatModal'
import ScrollTop from '@/components/ScrollTop'
import analytics from '@/analytics'

export default {
  name: 'Footer',
  components: { WeChatModal, FooterModal, ScrollTop },
  data: function () {
    return {
      isMounted: false,
      track: null,
      title: null,
      content: null,
      image: null
    }
  },
  computed: {
    modalTrack () {
      return this.track
    },
    modalTitle () {
      return this.title
    },
    modalContent () {
      return this.content
    },
    modalImage () {
      return this.image
    },
    iconFacebook () {
      return this.$store.state.global_content?.content?.followUs?.facebook
    },
    iconTwitter () {
      return this.$store.state.global_content?.content?.followUs?.twitter
    },
    iconWeChat () {
      return this.$store.state.global_content?.content?.followUs?.wechat
    },
    iconInstagram () {
      return this.$store.state.global_content?.content?.followUs?.instagram
    },
    iconDouyin () {
      return this.$store.state.global_content?.content?.followUs?.douyin
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    socialClicked (network) {
      analytics.track('social', 'open', network)
    },
    openModal (track, titleType, contentType, image) {
      this.track = track
      this.title = this.$store.getters.getTranslationsKey(titleType)
      this.content = this.$store.getters.getTranslationsKey(contentType)
      this.image = image || null
      this.$bvModal.show('footer-modal')
      analytics.track('footer', 'open', track)
    },
    openWeChatModal (event, track, titleType, image) {
      event.stopPropagation()
      event.preventDefault()
      this.track = track
      this.title = this.$store.getters.getTranslationsKey(titleType)
      this.image = image || null
      this.$bvModal.show('wechat-modal')
      analytics.track('social', 'open', 'wechat')
    }
  }
}
</script>

<style lang="scss" scoped>
.container-footer {
  background-color: getColor('mango');
  padding: 32px 0 0;
  color: getColor('dark-royal-blue');

  a {
    color: getColor('dark-royal-blue');
  }

  .social-icon {
    width: 56px;
    height: 56px;
  }

  .copyright {
    font-size: 10.7px;
    text-align: center;
  }

  .nav-link {
    padding: 0 0.5rem 6px;
  }

  .links {
    margin-bottom: 30px;
  }

  .scrollTop {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .col-social {
      justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      max-width: 400px;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: 26px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 36px 0 8px;

    .page-margin {
      align-items: flex-end;
    }

    .col-social {
      justify-content: flex-end;
      flex-wrap: nowrap;
      max-width: 400px;
      margin-bottom: 0;
    }

    .links {
      margin-bottom: 0;
    }

    .nav-link {
      padding: 0 0.5rem 6px;
    }

    .copyright {
      font-size: 12px;
      margin-top: 30px;
    }

    .follow-us-title {
      display: flex;
      padding: 0 0.5rem 6px;
      justify-content: flex-end;
      max-width: 400px;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: 18px;
    }

    .scrollTop {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    .col-social {
      max-width: initial;
    }

    .follow-us-title {
      max-width: initial;
      padding: 0 0.7rem 6px;
      margin-left: initial;
      margin-right: initial;
    }
  }

  @include media-breakpoint-down(sm) {
    .copyright {
      margin-bottom: 50px;
    }
  }
}
</style>
<style lang="scss">
.lang-zhHT {
  .container-footer {
    .copyright {
      font-size: 10px;
    }
  }
}
</style>
