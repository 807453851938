<template>
  <div class="activation-souvernir">
    <template v-if="activation.objectId == 'race-face' && hasSouvernirImage">
      <div class="embed-responsive embed-responsive-race-face" :class="activation.objectId">
        <div class="btn-clean">
          <Corner position="top-left" />
          <Corner position="top-right" />

          <video
            :ref="`video-${activation.objectId}`"
            :poster="souvernirImage"
            aria-label="View video"
            width="100%"
            preload
            class="souvenir-video"
            @click="playVideo(activation)"
          >
            <source
              :src="$store.state.vcc.content['race-face'] && $store.state.vcc.content['race-face'].raw"
              type="video/mp4"
            >
          </video>
          <!-- <a v-if="!isWeChat" class="download-button" :href="$store.state.vcc.content['race-face'] && $store.state.vcc.content['race-face'].downloadLink">{{ getTranslationsKey('TL_DOWNLOAD') }}</a> -->

          <Corner position="bottom-left" />
          <Corner position="bottom-right" />
        </div>
      </div>
      <MobileDownloadButton
        v-if="!isWeChat"
        class="mobile"
        :label="downloadCopy"
        theme-color="white"
        @clicked="downloadSouvenir"
      />
    </template>
    <template v-else-if="activation.objectId == 'suit-up' && hasSouvernirImage">
      <div class="embed-responsive embed-responsive-45by80" :class="activation.objectId">
        <div class="btn-clean">
          <Corner position="top-left" />
          <Corner position="top-right" />

          <video
            :ref="`video-${activation.objectId}`"
            :poster="souvernirImage"
            aria-label="View video"
            width="100%"
            preload
            class="souvenir-video"
            @click="playVideo(activation)"
          >
            <source :src="$store.state.vcc.content['suit-up'] && $store.state.vcc.content['suit-up'].raw" type="video/mp4">
          </video>
          <!-- <a v-if="!isWeChat" class="download-button" :href="$store.state.vcc.content['suit-up'] && $store.state.vcc.content['suit-up'].downloadLink">{{ getTranslationsKey('TL_DOWNLOAD') }}</a> -->

          <Corner position="bottom-left" />
          <Corner position="bottom-right" />
        </div>
        <MobileDownloadButton
          v-if="!isWeChat"
          class="mobile"
          :label="downloadCopy"
          theme-color="white"
          @clicked="downloadSouvenir"
        />
      </div>
    </template>
    <div v-else class="embed-responsive embed-responsive-45by80" :class="activation.objectId">
      <button
        class="btn-clean"
        :disabled="disabled || !hasSouvernirImage"
        @click="$emit('clicked', activation)"
      >
        <Corner position="top-left" />
        <Corner position="top-right" />

        <img
          :src="souvernirImage"
          alt="Souvenir Image"
          class="souvenir-image"
        >

        <Corner position="bottom-left" />
        <Corner position="bottom-right" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import MobileDownloadButton from '@/components/MobileDownloadButton'
import Corner from '@/components/Corner'
import { isWeChat } from '@/utils/browser'
import analytics from '@/analytics'

export default {
  name: 'Activations',
  components: { Corner, MobileDownloadButton },
  props: {
    activation: Object,
    disabled: Boolean,
    modal: Boolean
  },
  data () {
    return {
      isWeChat
    }
  },
  computed: {
    ...mapGetters(['getTranslationsKey']),
    hasSouvernirImage () {
      return !!this.$store.state.profiles?.content?.activationsPlayed[this.activation?.objectId]
    },
    souvernirImage () {
      // check if activation played
      let activationImage
      if (this.$store.state.profiles.content?.activationsPlayed[this.activation?.objectId]) {
        activationImage = `/profiles/${this.$store.state.profileId}/activations/${this.activation?.objectId}.png`
      } else {
        activationImage = this.$store.state.global_content.content
          .inactiveSouvenir.image
      }
      return activationImage
    },
    downloadCopy () {
      let contentType
      const vccContent = this.$store.state?.vcc.content[this.activation?.objectId]
      if (vccContent) {
        if (vccContent.raw.indexOf('.mp4') !== -1) {
          contentType = 'VIDEO'
        } else {
          contentType = 'PHOTO'
        }
      } else {
        contentType = 'IMAGE'
      }
      return this.getTranslationsKey(`TL_SOUVENIR_${contentType}`)
    }
  },
  methods: {
    playVideo (activation) {
      if (this.modal || window.innerWidth < 1030) {
        // mobile and tablet
        const $video = this.$refs[`video-${activation.objectId}`]

        if ($video.paused) {
          $video.play()
          // stop video remaining present when mobile device is closed and then re-openned
          $video.removeEventListener('webkitendfullscreen', this.endVideoFullscreen)
          $video.addEventListener('webkitendfullscreen', this.endVideoFullscreen)
        } else {
          $video.pause()
        }
      } else {
        // open modal on desktop
        Vue.openModal(activation)
      }
    },
    endVideoFullscreen (event) {
      event.target.pause()
      event.target.load()
    },
    downloadSouvenir () {
      const vccContent = this.$store.state?.vcc.content[this.activation?.objectId]

      let url = (vccContent && vccContent.downloadLink) || Vue.shareUrl(this.activation)
      let extension = url.search('.') ? url.split('.').pop() : 'png'
      let fileName = `${this.activation.objectId}.${extension}`
      analytics.track('souvenir', 'download', this.activation.objectId)
      Vue.downloadFile(url, fileName)
    }
  }
}
</script>

<style lang="scss">
.activation-souvernir {
  .btn-clean {
    width: 100%;
    // position: relative;
    position: absolute;
    top: 0;

    .souvenir-image,
    .souvenir-video {
      width: calc(100% - 28px);
      margin: 14px;
      border: 2px solid white;
    }
    .souvenir-video {
      margin: 14px 14px 8px;
    }
  }
}
@include media-breakpoint-up(xl) {

  .activation-souvernir {
    .btn-clean {
      .souvenir-video {
        width: 100%;
        margin: 0;
      }
    }
  }
  .modal-content {
    .activation-souvernir {
      .btn-clean {
        .souvenir-video {
          width: calc(100% - 28px);
          margin: 14px 14px 8px;
        }
      }
    }
  }
}
</style>
