/* Plugin to add the current language to the body and to load chinese webfont
    add language lang-enUS (etc) to the body as the modal dialogs are at the same level as id="app"
    and difficult to target with css and change the language font
*/

import { store } from '@/store'
// import { resolve as env } from '@/common/env'

export default {
  install (Vue) {
    // eslint-disable-next-line no-new
    new Vue({
      data: function () {
        return {
          lastLanguage: null
        }
      },
      computed: {
        languageChange () {
          return store?.state?.language?.current
        }
      },
      watch: {
        languageChange () {
          const el = document.body
          if (this.lastLanguage) { el.classList.remove(`lang-${this.lastLanguage}`) }

          el.classList.add(`lang-${store.state.language?.current}`)

          this.lastLanguage = store?.state?.language?.current

          // console.debug('Set language', this.lastLanguage)
          // /* Load chinese webfont */
          // if (this.lastLanguage === 'zhHT') {
          //   if (window.$webfont) {
          //     console.debug('Load chinese font')
          //     window.$webfont.load(
          //       'body',
          //       env('VUE_APP_YOUZIKU_KEY'),
          //       'SiYuanRegular'
          //     )
          //     /* $webfont.load("#id1,.class1,h1", "8eadd6e22b89435e9dc40ac638dace97", "SiYuanRegular"); */
          //     window.$webfont.draw()
          //   } else {
          //     console.warn(
          //       "Chinese webfont wasn't able to be draw has $webfont doesn't exist."
          //     )
          //   }
          // }
        }
      }
    })
  }
}
