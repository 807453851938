<template>
  <b-modal
    id="activation-modal"
    ref="activation-modal"
    size="xl"
    :scrollable="true"
    aria-label="Activation modal content"
    modal-class="activation-modal"
    :hide-header="true"
    :hide-footer="true"
    centered
  >
    <div class="modal-content activation-modal-content">
      <div
        class="section-title-container"
        :style="{ 'background-color': backgroundColor }"
      >
        <inline-svg
          v-if="activation"
          :src="activation.icon"
          width="70"
          height="70"
          aria-label="activation icon"
          aria-hidden="true"
          class="activation-icon"
          :style="{ color: '#FFFFFF' }"
        />
        <h2 class="title lang" :style="{ color: titleColor }">{{ title }}</h2>

        <b-button
          role="button"
          class="modal-close"
          tabindex="0"
          @click="closeModal"
        >
          <inline-svg
            :src="require('@/assets/svg/icon-close.svg')"
            width="30"
            height="30"
            aria-label="Close icon"
            aria-hidden="true"
            class="svg-primary close"
          />
          <span class="sr-only">Close modal</span>
        </b-button>
      </div>

      <b-container v-if="hasSouvernirImage" class="container-modal-content">
        <b-row>
          <b-col xl="7">
            <div class="activation-souvernir-modal-container">
              <ActivationType :activation="activation" :modal="true" />
            </div>
          </b-col>
          <b-col xl="5">
            <div class="download-container">
              <h2 class="download-title lang">
                {{ getTranslationsKey('TL_DOWNLOAD_YOUR_SOUVENIR') }}
              </h2>
              <GenericButton
                :label="downloadCopy"
                theme-color="white"
                @clicked="downloadSouvenir"
              />
              <h2 class="share-title lang">
                {{ getTranslationsKey('TL_SHARE_YOUR_ACHIEVEMENTS') }}
              </h2>
              <!-- Go to www.addthis.com/dashboard to customize your tools -->
              <AddThis
                public-id="ra-5fc70b60bcfdb43d"
                :data-url="dataUrl"
                :data-title="dataTitle"
                :data-description="dataDescription"
                :async="true"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else class="container-modal-content noSouvernirImage">
        <b-row>
          <b-col md="12">
            <div class="activation-souvernir-modal-container">
              <h2 class="download-title">
                {{ getTranslationsKey('TL_OOPS') }}
              </h2>
              <p v-html="getTranslationsKey('TL_NO_SOUVENIR')" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import ActivationType from '@/components/ActivationType'
import GenericButton from '@/components/GenericButton'
import AddThis from 'vue-simple-addthis-share'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import analytics from '@/analytics'

export default {
  name: 'ActivationModal',
  components: { ActivationType, GenericButton, AddThis },
  props: {
    activation: Object
  },
  data: function () {
    return {
      isMounted: false
    }
  },
  computed: {
    ...mapGetters(['getTranslationsKey']),
    selectedActivation () {
      return this.activation
    },
    hasSouvernirImage () {
      return !!this.$store.state.profiles?.content?.activationsPlayed[this.activation?.objectId]
    },
    objectId () {
      return this.activation?.objectId
    },
    backgroundColor () {
      return this.activation?.colors.background
    },
    icon () {
      return this.activation?.icon
    },
    iconColor () {
      return this.activation?.colors.icon
    },
    titleColor () {
      return this.activation?.colors.text
    },
    title () {
      return this.activation?.objectId
        ? this.getTranslationsKey(
          `TL_${this.activation?.objectId.toUpperCase()}`
        )
        : ''
    },
    downloadCopy () {
      let contentType
      const vccContent = this.$store.state?.vcc.content[this.activation?.objectId]
      if (vccContent) {
        if (vccContent.raw.indexOf('.mp4') !== -1) {
          contentType = 'VIDEO'
        } else {
          contentType = 'PHOTO'
        }
      } else {
        contentType = 'IMAGE'
      }
      return this.getTranslationsKey(`TL_SOUVENIR_${contentType}`)
    },
    dataUrl () {
      return Vue.shareUrl(this.activation)
    },
    dataTitle () {
      return this.getTranslationsKey('TL_TITLE')
    },
    dataDescription () {
      return this.activation?.objectId
        ? this.getTranslationsKey(
          `TL_${this.activation.objectId.toUpperCase()}`
        )
        : ''
    },
    dataMedia () {
      return Vue.shareUrl(this.activation)
    }
  },
  mounted () {
    this.isMounted = true
    this.checkAddThisLoaded()

    // console.log('AddThis', AddThis)
    // AddThis.mounted = (evt) => {
    //   console.log('mounted', evt, window.addthis)
    // }
  },
  methods: {
    checkAddThisLoaded () {
      setTimeout(() => {
        if (window.addthis) {
          console.debug('addthis.ready')
          window.addthis.addEventListener(
            'addthis.menu.share',
            this.shareEventHandler
          )
        } else {
          this.checkAddThisLoaded()
        }
      }, 100)
    },
    closeModal () {
      analytics.track('modal', 'close', this.activation.objectId)
      this.$bvModal.hide('activation-modal')
    },
    downloadSouvenir () {
      const vccContent = this.$store.state?.vcc.content[this.activation?.objectId]

      let url = (vccContent && vccContent.downloadLink) || Vue.shareUrl(this.activation)
      let extension = url.search('.') ? url.split('.').pop() : 'png'
      let fileName = `${this.activation.objectId}.${extension}`
      analytics.track('souvenir', 'download', this.activation.objectId)
      Vue.downloadFile(url, fileName)
    },
    shareEventHandler (evt) {
      if (evt.type === 'addthis.menu.share') {
        analytics.track('souvenir', 'share', this.activation?.objectId, evt.data.service)
      }
    }
  }
}
</script>

<style lang="scss">
.activation-modal {
  padding: 0 !important;

  .container-modal-content {
    // margin-top: 200px;
    margin-bottom: 40px;

    .activation-souvernir-modal-container {
      position: relative;
      margin: 30px;
      color: white;
    }

    &.noSouvernirImage {
      .activation-souvernir-modal-container {
        padding: 0px 40px;
      }

      .download-title {
        font-size: 80px !important;
        margin-bottom: 10px;
      }
      p {
        line-height: 1.3em;
      }
    }
  }

  .embed-responsive-45by80::before {
    padding-top: 172% !important;
  }

  .download-container {
    color: white;
    // margin-top: 60px;
    width: 84%;

    .download-title {
      line-height: 1.2em;
    }
    .share-title {
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    button.btn.generic-button {
      margin: 20px 0 70px;
    }

    /* Addthis */
    .addthis_inline_share_toolbox {
      min-height: 200px;
    }
    .at-share-btn-elements {
      display: grid;
      grid-template-columns: 100px 100px 100px;
      grid-template-rows: 100px 100px;
      max-width: 90%;
      margin-left: -20px;
    }
    .at-share-btn {
      justify-self: center;
      align-self: center;
      transform: scale(1.2);

      &:hover {
        transform: scale(1.2) !important;
      }
    }
    .at-share-btn {
      border: 2px solid white !important;
    }
  }

  /* Modal */
  .modal-body {
    margin: 5.5rem 1.2rem 0;
    padding: 0;
    background-color: getColor('dark-royal-blue');
    display: flex;

    .section-title-container {
      display: flex;
      align-items: center;
      padding: 2rem 2.8rem 2rem 2rem;
      @include zindex('scrollTop');
      .activation-icon {
        margin-right: 30px;

        .fill-to-change {
          fill: currentColor !important;
        }
        .stroke-to-change {
          stroke: currentColor !important;
        }
      }

      .title {
        flex-basis: 88%;
        font-size: 33px;
        line-height: 1.4em;
        margin-bottom: 0;
      }
    }

    .content {
      color: white;
      padding: 0 2.8rem 2rem 2rem;
    }
  }
  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: initial;
    // min-height: 100vh;
    overflow: initial !important;
  }
  .modal-close {
    @include zindex('scrollTop');
  }
  .activation-modal-content {
    display: block;
  }
  + .modal-backdrop {
    background-color: $modal-background-color;
    opacity: $modal-background-opacity;
  }

  @media only screen and (max-width: 1250px) {
    .download-container {
      .download-title {
        font-size: 24px;
      }
      .share-title {
        font-size: 24px;
      }
      button.btn.generic-button {
        .label {
          font-size: 18px;
        }
      }
      .at-share-btn-elements {
        grid-template-columns: 60px 60px 60px;
        grid-template-rows: 60px 60px;
        max-width: 90%;
        margin-left: -10px;
      }
      .at-share-btn {
        transform: scale(0.8) !important;
        transition: all 0.3s;

        .at-icon-wrapper > svg {
          transition: fill 0.3s;
        }

        &:hover {
          transform: scale(0.8) !important;
          background-color: white !important;

          .at-icon-wrapper > svg {
            fill: getColor('dark-royal-blue') !important;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1251px) {
  .activation-modal .download-container .at-share-btn {
    transform: scale(1.2) !important;
    transition: all 0.3s;

    .at-icon-wrapper > svg {
      transition: fill 0.3s;
    }

    &:hover {
      transform: scale(1.2) !important;
      background-color: white !important;

      .at-icon-wrapper > svg {
        fill: getColor('dark-royal-blue') !important;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .activation-modal {
    .download-container {
      display: none;
    }
  }
}
@include media-breakpoint-up(lg) {
  .activation-modal {
    // padding-top: 3.2vh;
    .download-container {
      margin-top: 60px;
    }

    .container-modal-content {
      margin-top: 200px;
    }

    .modal-content {
      min-height: auto;
    }

    .modal-dialog {
      max-width: initial;
      margin: 1.85rem 2.6rem;
    }

    .modal-body {
      margin: 0 12rem 0;
      max-height: 90vh;
      margin-top: 10vh;

      .section-title-container {
        position: fixed;
        width: calc(100% - 29.2rem);
        padding: 3.4rem 3.4rem 3rem 3.2rem;
        margin-top: -10px;

        .title {
          color: getColor('dark-royal-blue');
          font-size: 42px;
          line-height: 1em;
        }
      }
      .content {
        margin-top: 110px;
        padding: 5.4rem 5rem;
      }

      .embed-responsive-45by80 {
        &::before {
          padding-top: 170% !important;
        }
      }
    }
  }
}
</style>
