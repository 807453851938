<template>
  <transition duration="800">
    <div class="init">
      <main>
        <b-container class="container-init fade-in-out">
          <b-row>
            <b-col>
              <inline-svg
                :src="require('@/assets/svg/header-logo.svg')"
                width="200"
                height="200"
                aria-label="Macau Grand Prix Museum icon"
                aria-hidden="true"
                class="svg-tertiary logo"
              />
              <div class="text-center" role="status">
                <h1 class="h3 sr-only">Loading...</h1>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </main>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Init'
}
</script>

<style scoped lang="scss">
.init {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: $base-bg-color;
  color: white;
  @include zindex('init');
  pointer-events: none;

  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in-out {
    animation: fadeInOut 3s infinite;
  }
}
</style>
<style lang="scss">
.init {
  .logo {
    width: 250px;
    height: 250px;
    margin-top: -20px;

    .fill-to-change {
      fill: currentColor !important;
    }
  }
}
</style>
