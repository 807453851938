import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

// eslint-disable-next-line camelcase
export const global_content = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_GLOBAL_CONTENT
  }
}
