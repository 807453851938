<template>
  <b-navbar
    class="fade-in slow"
    :style="{ 'background-color': teamColorBackground, color: teamColorText }"
  >
    <b-container class="container-navigation" fluid>
      <b-row class="page-margin">
        <b-col>
          <inline-svg
            :src="require('@/assets/svg/header-logo.svg')"
            aria-label="Menu icon"
            aria-hidden="true"
            class="svg-tertiary logo"
          />
        </b-col>
        <b-col class="nav-menu-group text-right">
          <b-button
            v-b-modal.nav-modal
            class="nav-menu-btn"
            squared
            variant="primary"
            @click="openModal"
          >
            <inline-svg
              :src="require('@/assets/svg/icon-hamburger.svg')"
              aria-label="Menu icon"
              aria-hidden="true"
              class="svg-tertiary menu"
            />
            <span class="sr-only">Open menu</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="nav-modal"
      ref="nav-modal"
      size="xl"
      :scrollable="true"
      aria-label="Navigation Links"
      modal-class="nav-modal"
      :hide-header="true"
      :hide-footer="true"
    >
      <!-- <div class="placeholder" v-if="$store.state.config.debug">
        <img
          src="@/assets/placeholders/00-02_MyGarage(Desktop)_Homepage-LanguageSwitch.png"
          alt="Placeholder"
          class="show-desktop"
        />
        <img
          src="@/assets/placeholders/00-02_MyGarage(Tablet)_Homepage-LanguageSwitch.png"
          alt="Placeholder"
          class="show-tablet"
        />
        <img
          src="@/assets/placeholders/00-02_MyGarage(Mobile)_Homepage-LanguageSwitch.png"
          alt="Placeholder"
          class="show-mobile"
        />
      </div> -->

      <div class="background-activation-elements" :class="{ hidden: !isActivationHover }">
        <inline-svg
          :key="icon"
          :src="icon"
          width="70"
          height="70"
          aria-label="activation icon"
          aria-hidden="true"
          class="background-activation-icon"
          :style="{ color: activationHover && activationHover.colors.background }"
        />

        <inline-svg
          :src="require('@/assets/svg/arrow-big.svg')"
          width="70"
          height="70"
          aria-label="activation arrow"
          aria-hidden="true"
          class="background-activation-arrow-right"
          :style="{ color: activationHover && activationHover.colors.background }"
        />

        <inline-svg
          :src="require('@/assets/svg/arrow-big.svg')"
          width="70"
          height="70"
          aria-label="activation arrow"
          aria-hidden="true"
          class="background-activation-arrow-left"
          :style="{ color: activationHover && activationHover.colors.background }"
        />
      </div>

      <b-button
        role="button"
        class="nav-close"
        tabindex="0"
        @click="closeModal"
        @mouseleave="forgetActivationHover()"
      >
        <inline-svg
          :src="require('@/assets/svg/icon-close.svg')"
          width="30"
          height="30"
          aria-label="Close icon"
          aria-hidden="true"
          class="svg-primary close"
        />
        <span class="sr-only">Close menu</span>
      </b-button>

      <b-nav vertical class="page-margin">
        <b-nav-item
          class="languages-item"
          :class="{ isActive: languagesActive }"
          :link-attrs="{ tabindex: 0 }"
          @click="toggleLanguages"
          @mouseenter="forgetActivationHover()"
        >
          <h2 class="lang">
            {{ $store.getters['getTranslationsKey']('TL_LANGUAGE_LABEL') }}
          </h2>
          <inline-svg
            :src="require('@/assets/svg/arrow-right.svg')"
            width="16"
            height="16"
            aria-label="Arrow icon"
            aria-hidden="true"
            class="svg-primary arrow-icon"
          />
        </b-nav-item>
        <b-collapse id="collapse-nav-languages">
          <b-nav vertical class="collapse-nav-languages-list">
            <b-nav-item
              v-for="(language, index) in $store.state.languages"
              :key="language.code"
              :index="index"
              :class="{
                isActive: $store.state.language.current == language.code
              }"
              :link-attrs="{ tabindex: 0 }"
              @click="selectLanguage(language.code)"
              @mouseenter="forgetActivationHover()"
            >
              <h3 class="language-name" :class="`lang-${language.code}`">
                {{
                  $store.getters['getTranslationsKey'](
                    'TL_LANGUAGE',
                    language.code
                  )
                }}
              </h3>
            </b-nav-item>
          </b-nav>
        </b-collapse>

        <b-nav-item
          v-for="(activation, index) in sortedActivations"
          :key="activation.objectId"
          :index="index"
          class="menu-item"
          :class="{disabled: activationPlayed(activation)}"
          :link-attrs="{ tabindex: 0 }"
          :style="{ 'color': backgroundColor(activation) }"
          @click="navigate(activation.objectId)"
          @mouseenter="setActivationHover(activation)"
        >
          <h2 class="lang">
            {{
              $store.getters['getTranslationsKey'](
                `TL_${activation.objectId.toUpperCase()}`
              )
            }}
          </h2>
        </b-nav-item>
      </b-nav>
    </b-modal>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import analytics from '@/analytics'

export default {
  name: 'Navigation',
  components: {},
  data: function () {
    return {
      isMounted: false,
      languagesActive: false,
      activationHover: null
    }
  },
  computed: {
    ...mapGetters(['teamColorBackground', 'teamColorText', 'sortedActivations']),
    backgroundColor: () => {
      return (activation) => {
        return activation?.colors.background
      }
    },
    icon () {
      return this.activationHover?.icon ? this.activationHover?.icon : ''
    },
    isActivationHover () {
      return !!this.activationHover
    },
    activationPlayed () {
      return (activation) => {
        return !this.$store.state.profiles.content?.activationsPlayed[activation.objectId]
      }
    }

  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    navigate (touchpoint) {
      if (this.$store.state.profiles.content.activationsPlayed[touchpoint]) {
        this.closeModal()
        analytics.track('menu', 'navigate', touchpoint)
        setTimeout(() => {
          Vue.loadActivation(touchpoint)
        }, 300)
      }
    },
    selectLanguage (language) {
      this.$store.dispatch('language/SET_LANGUAGE', language)
      analytics.track('menu', 'language', language)
    },
    openModal () {
      analytics.track('menu', 'open')
    },
    closeModal () {
      this.languagesActive = false
      this.$bvModal.hide('nav-modal')
      analytics.track('menu', 'close')
    },
    toggleLanguages () {
      this.languagesActive = !this.languagesActive
      this.$root.$emit('bv::toggle::collapse', 'collapse-nav-languages')
      analytics.track('menu-language', this.languagesActive ? 'close' : 'open')
    },
    setActivationHover (activation) {
      if (this.$store.state.profiles.content.activationsPlayed[activation.objectId]) {
        this.activationHover = activation
      } else {
        this.forgetActivationHover()
      }
    },
    forgetActivationHover () {
      this.activationHover = null
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: 1rem;
  opacity: 0.9;
  z-index: 1;
  .container-navigation {
    > .row {
      width: 100vw;
      align-items: center;
    }

    .logo {
      width: 74px;
      height: auto;
    }
    .nav-menu-btn {
      width: 50px;
      height: auto;
      color: currentColor !important;

      > svg {
        width: 30px;
        height: 30px;
      }

      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}

.nav-close {
  position: fixed;
  top: 10px;
  right: 8px;

  @include media-breakpoint-up(md) {
    top: 18px;
    right: 12px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
  @include media-breakpoint-up(lg) {
    top: 25px;
    right: 20px;

    svg {
      width: 50px;
      height: 50px;
    }
  }
}
.languages-item {
  margin-top: 40px;
  .arrow-icon {
    position: relative;
    margin-top: -10px;
    margin-left: 20px;
    transform: rotate(90deg);
  }
  &.isActive {
    .arrow-icon {
      transform: rotate(270deg);
    }
  }
}
#collapse-nav-languages {
  transform: translateZ(0);
  will-change: transform;

  .collapse-nav-languages-list {
    padding: 10px 0 20px 32px;
  }
}
.nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 1rem;
}

.language-name {
  @extend .font-source-sans-pro-bold;
  font-size: 18.5px;
  margin-bottom: 3px;
}
@include media-breakpoint-up(md) {
  #collapse-nav-languages {
    .collapse-nav-languages-list {
      padding: 20px 0 22px 42px;
    }

    .nav-link {
      padding: 0;
      margin: 0 0 12px;
    }
  }

  .navbar {
    padding: 3% 0 3%;
    opacity: 1;
    background-color: transparent !important;
    position: absolute;
    width: 100vw;

    .container-navigation {
      .logo {
        width: 140px;
      }
      .nav-menu-btn {
        width: 50px;
      }

      .nav-menu-group {
        align-self: center;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .navbar {
    padding: 2rem 01rem;
    .container-navigation {
      .logo {
        width: 180px;
      }
      .nav-menu-btn {
        width: 64px;
        padding: 0;
        border: none;
        margin: 0 auto;

        > svg {
          width: 40px;
          height: 40px;
          transition: width 0.3s;
        }

        &:active,
        &:focus,
        &:hover {
          background-color: transparent !important;
          > svg {
            width: 48px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
// sticky nav on mobile only
@include media-breakpoint-down(sm) {
  body {
    .section-main {
      padding-top: 55px !important;
    }
  }

  .navbar {
    position: fixed !important;
    right: 0;
    left: 0;
    border-radius: 0;
    top: 0;
    @include zindex('navigation');
  }
}
@include media-breakpoint-up(md) {
  body {
    .section-main {
      padding-top: 95px !important;
    }
  }

  .navbar {
    position: absolute !important;
  }
}
@include media-breakpoint-up(lg) {
  body {
    .section-main {
      padding-top: 130px !important;
    }
  }

  button.btn-secondary.nav-close {
    svg {
      .stroke-to-change {
        transition: stroke 0.5s;
      }
    }

    &:hover {
      background-color: transparent !important;

      svg {
        .stroke-to-change {
          stroke: getColor('mango') !important;
        }
      }
    }
  }
  .languages-item,
  .collapse-nav-languages-list {
    .nav-link {
      &:hover {
        color: getColor('mango') !important;
        .arrow-icon {
          .fill-to-change {
            fill: getColor('mango') !important;
          }
        }
      }
    }
  }
}
.nav-modal {
  .nav-item {
    &.isActive {
      a {
        color: orange;

        .fill-to-change {
          fill: orange !important;
        }
      }
    }
  }
  .menu-item {
    text-transform: uppercase;
    a.nav-link {
      color: inherit;
      h2 {
        color: white !important;
      }
    }
    &:hover:not(.disabled) {
      a.nav-link {
        h2 {
          color: currentColor !important;
        }
      }
    }
    &.disabled {
      a.nav-link {
        opacity: 0.25;
        cursor:default;
      }

    }
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: initial;
    min-height: 100vh;

    .modal-body {
      padding: 2.2rem 0 0;
    }

    @include media-breakpoint-up(md) {
      .modal-body {
        padding: 3.4rem 0 0 3rem;
      }
    }

    @include media-breakpoint-up(xl) {
      .modal-body {
        padding: 5rem 0;
      }
    }
  }
  + .modal-backdrop {
    background-color: $modal-background-color;
    opacity: $nav-modal-background-opacity;
  }
}
@include media-breakpoint-down(lg) {
  .background-activation-elements {
    display: none;
  }
}
@include media-breakpoint-up(xl) {
  .background-activation-elements {
    &.hidden {
      .background-activation-arrow-right,
      .background-activation-arrow-left,
      .background-activation-icon {
        .fill-to-change,
        .class-to-fill {
          fill: transparent !important;
        }
        .stroke-to-change {
          stroke: transparent !important;
        }
      }
    }
    .background-activation-arrow-right {
      height: 100vh;
      width: 100vw;
      position: fixed;
      right: 0; // -40%
      top: 0;
      transform: scale(1.4);
      pointer-events: none;
      .fill-to-change {
        transition: all 0.5s;
      }
    }
    .background-activation-arrow-left {
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: -110%; // -70%
      top: 0;
      transform: scale(1.4);
      pointer-events: none;
      .fill-to-change {
        transition: all 0.5s;
      }
    }
    .background-activation-icon {
      position: fixed;
      right: 130px;
      top: 43%;
      width: 130px;
      height: 130px;
      pointer-events: none;
      .fill-to-change,
      .class-to-fill {
        transition: all 0.5s;
      }
      .stroke-to-change {
        transition: all 0.5s;
      }
    }
    .background-activation-arrow-right,
    .background-activation-arrow-left,
    .background-activation-icon {
      .fill-to-change,
      .class-to-fill {
        fill: currentColor !important;
      }
      .stroke-to-change {
        stroke: currentColor !important;
      }
    }
  }
}
</style>
<style lang="scss">
.navbar {
  .logo {
    .fill-to-change {
      fill: currentColor !important;
    }
  }
  .menu {
    .stroke-to-change {
      stroke: currentColor !important;
    }
  }
}
</style>
