// default config values
export const defaultConfig = {
  serverApi: '/api', // Example: "https://dev.orchestration.mgto.ps-dev.cn"
  debug: false
}

// setup a basic vuex interface to the config storage
export const config = {
  namespaced: true,
  state: defaultConfig,
  actions: {
    set ({ state, commit }, { key, value }) {
      state.set(key, value)
      commit('set', { key, value })
    }
  },
  mutations: {
    set (state, { key, value }) {
      state[key] = value
    }
  }
}
