<template>
  <div class="main-page" style="color: #fff">
    images

    <div v-if="profile && activations">
      <template v-for="activation in activations">
        <img
          v-if="profile.activationsPlayed[activation.objectId]"
          :key="activation.objectId"
          :src="`/profiles/${profileId}/activations/${activation.objectId}.png`"
        >
      </template>
    </div>

    profile
    <pre> {{ profile }}</pre>

    <hr>
    activations
    <pre> {{ activations }}</pre>

    <hr>
    achievements
    <pre> {{ achievements }}</pre>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
export default {
  name: 'Main',
  data () {
    return {
      profileId: 'd5d30c5d7c24406fb00cfaa925fb1cfe',
      achievements: [],
      activations: null,
      profile: null
    }
  },
  async mounted () {
    this.profileId = _.get(this.$route, 'params.profileId', this.profileId)
    let response

    response = await axios.get(`/api/profiles/${this.profileId}`)
    this.profile = response.data

    response = await axios.get('/api/achievements')
    console.log('achievements', response)
    this.achievements = response.data

    response = await axios.get('/api/activations')
    this.activations = response.data
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  display: inline-block;
  width: 200px;
}
</style>
