<template>
  <transition>
    <div
      v-show="showScrollTop"
      class="scrollTop fade-in slow delay4"
      :class="{ isInline: inline }"
    >
      <b-button variant="outline-primary lang" class="top" @click="scroll">
        <inline-svg
          :src="require('@/assets/svg/arrow-right.svg')"
          width="12"
          height="12"
          aria-label="Arrow icon"
          aria-hidden="true"
          class="svg-primary arrow-icon"
        />{{ $store.getters['getTranslationsKey']('TL_TOP') }}
      </b-button>
    </div>
  </transition>
</template>

<script>
import analytics from '@/analytics'
import { isWeChat } from '@/utils/browser'
import { top } from '@/utils/position'

export default {
  name: 'ScrollTop',
  props: ['inline'],
  data: function () {
    return {
      showScrollTop: !!this.inline
    }
  },
  created () {
    if (!this.inline) {
      window.addEventListener('scroll', this.handleScroll)
      this.$root.$on('bv::modal::shown', (event) => {
        document
          .querySelector('.modal-body')
          .addEventListener('scroll', this.handleScroll)
        this.handleScroll()
        document.querySelector('.modal-open').classList.add(`modal-id-${event.componentId}`)
      })
      this.$root.$on('bv::modal::hidden', (event) => {
        document
          .querySelector('.modal-body')
          ?.removeEventListener('scroll', this.handleScroll)
        this.$nextTick(this.handleScroll)
        document.querySelector('.modal-open').classList.remove(`modal-id-${event.componentId}`)
      })
    }
  },
  destroyed () {
    if (!this.inline) {
      window.removeEventListener('scroll', this.handleScroll)
      document
        .querySelector('.modal-body')
        ?.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    scroll () {
      let $target
      if (document.body.classList.contains('modal-open')) {
        $target = document.querySelector('.modal-body')
      } else {
        $target = window
      }
      if (isWeChat) {
        $target.scrollTo(0, 0)
      } else {
        $target.scrollTo({ top: 0, behavior: 'smooth' })
      }
      if (this.inline) {
        analytics.track('scroll-top', 'from-footer')
      } else {
        analytics.track('scroll-top', 'from-page')
      }
    },
    handleScroll () {
      const modal = document.body.classList.contains('modal-open')
      if (this.$store.state.config.debug) {
        document.querySelector(
          '.placeholder'
        ).style.marginTop = `-${top()}px`
      }
      if (modal) {
        this.showScrollTop = document.querySelector('.modal-body').scrollTop > 0
      } else {
        this.showScrollTop = top() > 0
      }
    }
  }
}
</script>

<style lang="scss">
.scrollTop {
  .top {
    position: fixed;
    right: 0;
    bottom: 20px;
    height: 70px;
    border-radius: initial;
    @extend .font-fjallaOne;
    text-transform: uppercase;
    font-size: 22px;
    padding: 1rem 0.5rem 0;
    color: white;
    border-color: white;
    border-right: 0;
    background-color: rgba(255, 255, 255, 0.4);
    @include zindex('scrollTop');

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:active {
      color: white;
      background-color: rgba(255, 255, 255, 0.6);
      border-color: white;
      box-shadow: none;
    }

    .arrow-icon {
      position: absolute;
      top: 7px;
      left: calc(50% - 8px);
      transform: rotate(270deg);
    }
  }

  &.isInline {
    .top {
      height: 68px;
      padding-top: 14px;
      position: absolute;
      background: none;
      border: 1px solid transparent !important;
      transform: scale(0.8);
      top: -110px;
      right: 20px;
      color: getColor('dark-royal-blue');

      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:active {
        color: getColor('dark-royal-blue');
      }

      &:hover {
        border: 1px solid white !important;
        background-color: rgba(255, 255, 255, 0.3);
        color: white;

        .arrow-icon {
          .fill-to-change {
            fill: white !important;
          }
        }
      }
      .arrow-icon {
        top: 12px;

        .fill-to-change {
          fill: getColor('dark-royal-blue') !important;
        }
      }

      @include media-breakpoint-up(md) {
        top: 100px;
        right: -30px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .modal-id-activation-modal .scrollTop {
    display: none;
  }
}
@include media-breakpoint-up(md) {
  body:not(.modal-open) {
    .scrollTop:not(.isInline) {
      display: none !important;
    }
  }
  body.modal-open {
    .scrollTop:not(.isInline) {
      display: none !important;
    }
  }
}
</style>
