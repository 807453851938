import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Database from '../views/Database.vue'
import analytics from '@/analytics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/profiles/:profile',
    name: 'Home',
    component: Home,
    children: [
      {
        path: ':touchpoint',
        name: 'Touchpoint'
      }
    ]
  },
  {
    path: '/styleguide/colors',
    name: 'Colors',
    component: () =>
      import(/* webpackChunkName: "color" */ '../views/styleguide/Colors.vue')
  },
  {
    path: '/styleguide/languages',
    name: 'Languages',
    component: () =>
      import(
        /* webpackChunkName: "languages" */ '../views/styleguide/Languages.vue'
      )
  },
  {
    path: '/database',
    name: 'Database',
    component: Database
  },
  {
    path: '/database/:profileId',
    name: 'DatabaseProfile',
    component: Database
  },
  {
    path: '/error',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue')
  },
  { path: '*', redirect: '/error' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // track
  analytics.page(to.name)
  next()
})

export default router
