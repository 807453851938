import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const vcc = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_VCC
  }
}

/* Data Structure

{
race-face: "https://imag-it.oss-cn-hangzhou.aliyuncs.com/mgto/race-face/videos/1606710499.515895_sample-mp4-file.mp4",
suit-up: "https://imag-it.oss-cn-hangzhou.aliyuncs.com/mgto/suit-up/videos/1606710546.215309_sample-mp4-file.mp4",
podium: "https://imag-it.oss-cn-hangzhou.aliyuncs.com/mgto/podium/images/1606712535.932137_11-01_Souvenirs_ThePodium_-_Rich_Media.png"
}

*/
