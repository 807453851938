<template>
  <inline-svg
    :src="require('@/assets/svg/corner.svg')"
    width="20"
    height="20"
    aria-label="corner"
    aria-hidden="true"
    :class="`corner corner-${position}`"
  />
</template>

<script>
export default {
  name: 'Corner',
  components: {},
  props: {
    position: String
  }
}
</script>

<style lang="scss">
.corner {
  position: absolute;
  opacity: 0.8;

  .fill-to-change {
    fill: rgba(255, 255, 255, 0.8) !important;
  }

  &.corner-top-left {
    top: 0;
    left: 0;
    transform: scale(0.8);
  }

  &.corner-top-right {
    top: 0;
    right: 0;
    transform: rotate(90deg) scale(0.8);
  }

  &.corner-bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(270deg) scale(0.8);
  }

  &.corner-bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(180deg) scale(0.8);
  }
}
</style>
