import Vue from 'vue'
import Vuex from 'vuex'
import { config } from './config'
import { language } from './language'
import { activations } from './activations'
import { achievements } from './achievements'
import { teams } from './teams'
import { profiles } from './profiles'
// eslint-disable-next-line camelcase
import { global_content } from './global_content'
import { translations } from './translations'
import { application } from './application'
import { vcc } from './vcc'

Vue.use(Vuex)

export const store = new Vuex.Store({
  ...application,
  modules: {
    config,
    language,
    activations,
    achievements,
    teams,
    profiles,
    vcc,
    global_content,
    translations
  }
})
