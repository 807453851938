import { store, mutators as baseMutators } from './_base_api_content'

export const mutators = {
  ...baseMutators
}

export const teams = {
  ...store,
  state: {
    ...store.state,
    cmsKey: process.env.VUE_APP_TEAMS
  }
}

/* Data Structure

{
    "key": "rallyRed",
    "_id": "khoaodikkho9huckysift9sh",
    "colour": "#ff503c",
    "hasWhiteBorder": false,
    "colourSchemes": {
        "a": {
            "avatar": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1wawkho9hucky21ijqm4",
            "closePov": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1wflkho9hucky8pihj2u",
            "fullBody": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1wjrkho9hucky0efrqrf",
            "upperBody": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1woqkho9huckyiok91tk"
        },
        "b": {
            "avatar": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xankho9huckygqaaxhe",
            "closePov": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xfykho9huckyq0282dw",
            "fullBody": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xjtkho9huckyq80vzy9",
            "upperBody": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xnnkho9hucky0uo7mzj"
        }
    },
    "icon": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xonkho9huckyh1uftv4",
    "iconPng": "/cms/api/teams/khoaodikkho9huckysift9sh/attachments/khob1xpukho9huckybfvnahm",
    "name": {
        "enUS": "Red Rally",
        "ptPT": "Vermelho Rally",
        "zhHT": "拉力红"
    }
}

*/
